import React, { Fragment, useState, useEffect, useLayoutEffect } from 'react';

// Hook
export default function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [ minSize, setMinSize ] = useState(null)

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
        // Set window width/height to state
        // setWindowSize({
        //     width: window.innerWidth,
        //     height: window.innerHeight,
        // });
        const width = window.innerWidth
        const height = window.innerHeight
        const narrowest = (width > height) ? height : width
        const tmpDiam = (narrowest > 750) ? 750 : narrowest
        setMinSize(tmpDiam)
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return minSize;
}