import React, { useEffect, useState } from 'react';
import { Box, Typography, Snackbar } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import getProfileCode from '../Functions/getProfileCode.js';

export default function ProfileCode(props) {
    const t = props.t
    const l = props.l
    const [ code, setCode ] = useState('')
    const [ open, setOpen ] = useState(false);
    const profile = props.profile
    const codeWidth = 250
    const codeHeight = 40

    useEffect(() => {
        const tmpCode = getProfileCode(profile)
        if (code !== tmpCode) {
            setCode(tmpCode)
            if ( props.session.profileCode !== tmpCode ) {
                let tmpSession = props.session
                tmpSession.profileCode = tmpCode
                props.updateSession(tmpSession)
            }
        }
    })

    function copyCode(event, code){
        navigator.clipboard.writeText(code);
        setOpen(true)
    }

    function handleClose(){
        setOpen(false);
    }

    return (
        <Box width={ codeWidth } display='flex' flexDirection='column' justifyContent='center'>
            <Box>
                <Typography variant="subtitle2" align='center'>
                    { t('title', 'your-profile-code', l) } <br/> <span className="noprint">{ t('title', 'click-to-copy', l) }</span>
                </Typography>
            </Box>
            <Box pt={1} width={ codeWidth } height={ codeHeight } bgcolor="yellow" borderRadius="40px" lineHeight="40px"
                style={{cursor: "copy"}} border="solid 3px black" onClick={ (event) => copyCode(event, code) }>
                <Typography variant="h5" align='center'>{ code }</Typography>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={open}
                onClose={handleClose}
                message="Profile Code copied to clipboard!"
                autoHideDuration={6000}
            />
        </Box>
    )
}