export default function Translate(type, label, lang) {

    // console.log( type )
    // console.log( label )
    // console.log( lang )

    if (lang === null) return null

    const dictionary = {
        // ALERT
        alert: {
            "proper-name-required": {
                en: "Proper name required!",
                es: "Nombre propio requerido",
                pt: "Insira seu nome"
            },
            "valid-email-required": {
                en: "Valid Email is required!",
                es: "Es necesario un email válido",
                pt: "Insira o seu email"
            },
            "proper-code-required": {
                en: "Proper code required!",
                es: "Nombre propio requerido",
                pt: "Insira seu nome"
            }
        },
        // LINK
        link: {
            "learn-more-at-radical-purpose": {
                en: "Learn more at RadicalPurpose.org",
                es: "Saber más en RadicalPurpose.org",
                pt: "Saiba mais em RadicalPurpose.org"
            }
        }, 
        // ROUTE
        route: {
            "profile": {
                en: "profile",
                es: "perfil",
                pt: "perfil"
            },
            "feelings": {
                en: "feelings",
                es: "sentimientos",
                pt: "sentimentos"
            },
            "needs": {
                en: "needs",
                es: "necesidades",
                pt: "necessidades"
            },
            "team": {
                en: "team",
                es: "equipo",
                pt: "equipe"
            },
            "report": {
                en: "report",
                es: "reporte",
                pt: "relatório"
            }
        },
        // BUTTON
        button: {
            "profile": {
                en: "Profile",
                es: "Perfil",
                pt: "Perfil"
            },
            "feelings": {
                en: "Feelings",
                es: "Sentimientos",
                pt: "Sentimentos"
            },
            "needs": {
                en: "Needs",
                es: "Necesidades",
                pt: "Necessidades"
            },
            "team": {
                en: "Team",
                es: "Equipo",
                pt: "Equipe"
            },
            "retake": {
                en: "Retake",
                es: "Retomar",
                pt: "Refazer"
            },
            "continue": {
                en: "Continue",
                es: "Continuar",
                pt: "Continuar"
            },
            "view-team-profile": {
                en: "View Team Profile",
                es: "Ver Perfil del Equipo",
                pt: "Ver Perfil da Equipe"
            },
            "add-member": {
                en: "Add Member",
                es: "Añadir Miembro",
                pt: "Adicionar Membro"
            },
            "go-back": {
                en: "< go back",
                es: "< volver",
                pt: "< voltar"
            },
            "print-report": {
                en: "print report",
                es: "imprimir el informe",
                pt: "imprimir seu perfil"
            },
            "add-profile-code": {
                en: "add profile code",
                es: "add profile code",
                pt: "inserir código do perfil"
            },
        },
        // TITLE
        title: {
            "radical-purpose": {
                en: "RADICAL PURPOSE",
                es: "PROPÓSITO RADICAL",
                pt: "PROPÓSITO RADICAL"
            },
            "radical-purpose-profile": {
                en: "Radical Purpose Profile",
                es: "Perfil de Propósito Radical",
                pt: "Perfil do Propósito Radical"
            },
            "profile": {
                en: "PROFILE",
                es: "PERFIL",
                pt: "PERFIL"
            },
            "team": {
                en: "TEAM",
                es: "EQUIPO",
                pt: "EQUIPE"
            },
            "team-report": {
                en: "TEAM REPORT",
                es: "INFORME DEL EQUIPO",
                pt: "PERFIL DA EQUIPE"
            },
            "select-language": {
                en: "Please select your language:",
                es: "Por favor seleccione su idioma:",
                pt: "Por favor selecione seu idioma:"
            },
            "cc-license": {
                en: "Licensed under Creative Commons",
                es: "Licencia bajo Creative Commons",
                pt: "Licenciado sob Creative Commons"
            },
            "understanding-yourself": {
                en: "UNDERSTANDING YOURSELF",
                es: "ENTENDIÉNDOSE A SÍ MISMO",
                pt: "ENTENDENDO-SE"
            },
            "team-profiles": {
                en: "Team Profiles",
                es: "Perfil del Equipo",
                pt: "Perfil da Equipe"
            },
            "add-member": {
                en: "Add Member",
                es: "Añadir Miembro",
                pt: "Adicionar Membro"
            },
            "what-is-important-to-you": {
                en: "What is important to you?",
                es: "Qué es importante para ti",
                pt: "O que é importante para você?"
            },
            "what-gives-you-energy": {
                en: "What gives you energy?",
                es: "Qué te da energía",
                pt: "O que te dá energia?"
            },
            "what-motivates-your-decisions": {
                en: "What motivates your decisions?",
                es: "Qué motiva tus decisiones",
                pt: "O que motiva suas decisões?"
            },
            "your-rights": {
                en: "YOUR RIGHTS",
                es: "TUS DERECHOS",
                pt: "SEUS DIREITOS"
            },
            "instructions": {
                en: "INSTRUCTIONS",
                es: "Instrucciones",
                pt: "INSTRUÇÕES"
            },
            "of": {
                en: "of",
                es: "de",
                pt: "de"
            },
            "i-prefer": {
                en: "I prefer...",
                es: "Yo prefiero...",
                pt: "Eu prefiro..."
            },
            "radical-purpose-profile-for": {
                en: "Radical Purpose Profile for",
                es: "Perfil de Propósito Radical para",
                pt: "Perfil do Propósito Radical para"
            },
            "completed-in": {
                en: "Completed in",
                es: "Completado en",
                pt: "Concluído em"
            },
            "minutes": {
                en: "minutes",
                es: "minutos",
                pt: "minutos"
            },
            "seconds": {
                en: "seconds",
                es: "segundos",
                pt: "segundos"
            },
            "created": {
                en: "Created",
                es: "Creado",
                pt: "Criado"
            },
            "accuracy-note": {
                en: "Accuracy Note",
                es: "Nota de Precisión",
                pt: "Grau de Precisão"
            },
            "your-five-radical-needs": {
                en: "Your Five Radical Needs",
                es: "Tus Cinco Necesidades Radicales",
                pt: "Suas Cinco Necessidades Radicais"
            },
            "your-needs-radar": {
                en: "Your Needs Radar",
                es: "Tu Radar de Necesidades",
                pt: "Seu Radar de Necessidades"
            },
            "your-profile-code": {
                en: "YOUR PROFILE CODE",
                es: "CÓDIGO DE TU PERFIL",
                pt: "CÓDIGO DO SEU PERFIL"
            },
            "click-to-copy": {
                en: "(click to copy)",
                es: "(haga clic para copiar)",
                pt: "(clique para copiar)"
            },
            "your-radical-needs": {
                en: "Your Radical Needs",
                es: "Tus Necesidades Radicales",
                pt: "Suas Necessidades Radicais"
            },
            "High": {
                en: "High",
                es: "Alto",
                pt: "Alto"
            },
            "Med": {
                en: "Med",
                es: "Medio",
                pt: "Médio"
            },
            "Low": {
                en: "Low",
                es: "Bajo",
                pt: "Baixo"
            },
            "browse-list-of-needs": {
                en: "Browse List of Needs",
                es: "Navegar por la Lista de Necesidades",
                pt: "Navegar na Lista de Necessidades"
            },
            "being-needs": {
                en: "Being Needs",
                es: "Necesidades de Ser",
                pt: "Necessidades de Ser"
            },
            "meaning-needs": {
                en: "Meaning Needs",
                es: "Necesidades de Significar",
                pt: "Necessidades de Significado"
            },
            "impacting-needs": {
                en: "Impacting Needs",
                es: "Necesidades de Impactar",
                pt: "Necessidades de Impacto"
            },
            "belonging-needs": {
                en: "Belonging Needs",
                es: "Necesidades de Pertenecer",
                pt: "Necessidades of Pertencimento"
            },
            "becoming-needs": {
                en: "Becoming Needs",
                es: "Necesidades de Devenir",
                pt: "Necessidades de Evolução"
            },
            "exercise": {
                en: "Exercise",
                es: "Ejercicio",
                pt: "Exercício"
            },
            "need": {
                en: "Need",
                es: "Necesidade",
                pt: "Necessidade"
            }
        },
        // PARAGRAPH
        paragraph: {
            "discover-your-innate-needs": {
                en: "Discover your innate needs and how they define your Radical Purpose in life.",
                es: "Descubre tus necesidades innatas y cómo ellas definen tu Propósito de Vida.",
                pt: "Descubra suas necessidades inatas e como você define o seu Propósito Radical na vida."
            },
            "learning-to-see-your-radical-purpose": {
                en: "Learning to see your Radical Purpose in your daily interactions provides you with the insights that enhance your life and the lives of those around you.",
                es: "Aprender a ver tu Propósito Radical en tus interacciones diarias te brinda el conocimiento para mejorar tu vida y la vida de quienes te rodean.",
                pt: "Compreender o seu Propósito Radical nas suas interações diárias proporciona insights que melhoram a sua vida e as vidas daqueles ao seu redor."
            },
            "the-radical-purpose-profile": {
                en: "The Radical Purpose Profile is a 6 minute assessment that provides insights into who you are.",
                es: "El Perfil de Propósito Radical es una evaluación de 6 minutos que te brinda destellos para comprender quién tu eres.",
                pt: "O Perfil do Propósito Radical é uma avaliação de 6 minutos que amplia a sua consciência sobre quem você é."
            },
            "the-personal-information-you-provide": {
                en: "The personal information you provide will not be shared with other individuals or organizations. The results of your profile may be used by the Radical team to improve this tool and in aggregate for ongoing research. Your information is stored on this device for future use.",
                es: "La información personal suministrada no será compartida con otras personas u organizaciones. Los resultados de tu perfil pueden ser usados por el equipo Radical para mejorar esta herramienta y ser agregado para la investigación en curso.",
                pt: "As informações pessoais que você fornece não serão compartilhadas com outros indivíduos ou organizações. Os resultados do seu perfil podem ser usados pela nossa equipe para melhorar esta ferramenta e para pesquisas em andamento. Suas informações são armazenadas neste dispositivo para uso futuro."
            },
            "you-will-be-provided": {
                en: "You will be provided 25 sets of statements.",
                es: "Se te suministrarán 25 conjuntos de declaraciones.",
                pt: "Você terá 25 questões para analisar."
            },
            "you-need-to-select": {
                en: "You need to select the 2 statements that you most prefer.",
                es: "Necesitas seleccionar las 2 declaraciones que prefieres.",
                pt: "Selecione suas 2 frases preferidas em cada questão."
            },
            "these-should-reflect-who": {
                en: "These should reflect who you are - what feels right to you, not who you desire to become.",
                es: "Estas deben reflejar quién eres - qué sentimientos son buenos para ti, no quién deseas llegar a ser.",
                pt: "Estas frases devem refletir quem você é, não aquilo que você deseja ser."
            },
            "dont-overthink-it": {
                en: "Don't overthink it; the assessment is designed to take about 6 minutes (time is tracked).",
                es: "No lo pienses demasiado, La evaluación está diseñada para durar unos 6 minutos (se realiza un seguimiento del tiempo).",
                pt: "Não pense demais; a avaliação é desenhada para durar aproximadamente 6 minutos (o tempo é controlado)."
            },
            "when-you-finish": {
                en: "When you finish, your brief profile will be generated and a copy emailed to you.",
                es: "Cuando finalices se generará tu perfil y recibirás una copia por correo electrónico.",
                pt: "Quando você concluir, o seu perfil será gerado e uma cópia será enviada para você."
            },
            "survey-please-click-tap": {
                en: "please click/tap the 2 STATEMENTS that most reflect the real you.",
                es: "Por favor, pulsa sobre las dos declaraciones que mejor reflejan tu yo real.",
                pt: "por favor clique nas 2 frases que mais refletem realmente você."
            },
            "survey-once-you-have": {
                en: "Once you have selected the second statement, the next set automatically displays. If needed, you can click the 'GO BACK' button to return to the previous page.",
                es: "Una vez hayas seleccionado una segunda declaración, se mostrará el siguiente conjunto. Si necesitas puedes hacer clic para VOLVER a la página anterior.",
                pt: "Uma vez que você tenha selecionado a segunda frase, a próxima questão aparecerá. Se necessário, você pode clicar em 'VOLTAR' para retornar à página anterior."
            },
            "based-on-our-research-tooslow": {
                en: "Based on our research, assessments done in over",
                es: "Según nuestra investigación, evaluaciones realizadas en más de",
                pt: "Com base em nossa pesquisa, as avaliações feitas em mais de"
            },
            "based-on-our-research-toofast": {
                en: "Based on our research, assessments done in under",
                es: "Según nuestra investigación, evaluaciones realizadas en menos de",
                pt: "Com base em nossa pesquisa, as avaliações feitas em menos de"
            },
            "minutes-may-not-be": {
                en: "minutes may not be accurate.",
                es: "minutos pueden no ser exactos.",
                pt: "minutos podem não ser precisas."
            },
            "generally-accurate-profiles-tooslow": {
                en: "Generally, accurate profiles take under",
                es: "Generalmente, los perfiles precisos tardan menos de",
                pt: "Geralmente, os perfis precisos levam menos de"
            },
            "generally-accurate-profiles-toosfast": {
                en: "Generally, accurate profiles take at least",
                es: "Generalmente, los perfiles precisos tardan al menos",
                pt: "Geralmente, perfis precisos levam pelo menos"
            },
            "minutes-you-may-want": {
                en: "minutes. You may want to retake it when you have time.",
                es: "minutos. Es posible que desees volver a realizarlo cuando tengas tiempo.",
                pt: "minutos. Você pode querer refazê-lo quando tiver tempo."
            },
            "the-radical-purpose-framework": {
                en: "The Radical Purpose framework measures five innate needs: Being, Meaning, Impacting, Belonging, and Becoming - each need represents subneeds that play a specific role in motivating our feelings, thoughts, and behaviors. Some needs may be higher in you and play a greater role in your life. It is these differences in needs and their subneeds that make you unique.",
                es: "El marco del Propósito Radical mide cinco necesidades innatas: Ser, Significar, Impactar, Pertenecer y Devenir; cada necesidad representa necesidades secundarias que desempeñan un papel específico en la motivación de nuestros sentimientos, pensamientos y comportamientos. Algunas necesidades pueden ser mayores en ti y jugar un papel más importante en tu vida. Son estas diferencias en las necesidades y tus subnecesidades las que te hacen único.",
                pt: "A estrutura do Propósito Radical mede cinco necessidades inatas: Ser, Significado, Impacto, Pertencimento e Evolução - cada necessidade representa subnecessidades que desempenham um papel específico na motivação de nossos sentimentos, pensamentos e comportamentos. Algumas necessidades podem ser altas em você e desempenhar um papel maior em sua vida, porém todas estão presentes. São essas diferenças de necessidades e suas subnecessidades que o tornam único."
            },
            "people-are-usually-unaware": {
                en: "People are usually unaware of the influence of their radical needs. Bringing them into your awareness is an important step towards enhancing the quality of your life and the lives of those around you.",
                es: "Las personas generalmente desconocen la influencia de sus necesidades radicales. Traerlas a tu conciencia es un paso importante para mejorar tu calidad de vida y la vida de quienes te rodean.",
                pt: "As pessoas geralmente não estão conscientes da influência das suas Necessidades Radicais. Trazer isso para a consciência é um passo importante para melhorar a qualidade de sua vida e as vidas daqueles a seu redor."
            },
            "the-following-are-radical-needs": {
                en: "The following are your Radical Needs. Their sub-needs motivate the feelings, thoughts, and behaviors that express your Radical Purpose.",
                es: "Las siguientes son tus Necesidades Radicales. Tus sub necesidades motivan los sentimientos, pensamientos y comportamientos que expresan tu Propósito Radical.",
                pt: "A seguir apresentamos suas necessidades radicais. As subnecessidades motivam seus sentimentos, pensamentos e comportamentos que expressam o seu Propósito Radical."
            },
            "hello": {
                en: "Hello",
                es: "Hola",
                pt: "Olá",
            },
            "thank-you-for-completing": {
                en: "Thank you for completing the Radical Purpose Profile.",
                es: "Hola, gracias por completar el Perfil de tu Propósito Radical",
                pt: "Obrigado por completar seu Perfil de Propósito Radical.",
            },
            "we-are-still-in-beta": {
                en: "We are still in Beta, and many features such as this email are limited.",
                es: "Todavía estamos en fase beta, y muchas funciones, como este correo electrónico, pueden ser limitadas.",
                pt: "Nós ainda estamos em versão Beta e muitos dos recursos, como este e-mail, são limitados.",
            },
            "below-is-the-results": {
                en: "Below is the results of your profile:",
                es: "Abajo encontrarás los resultados de tu perfil:",
                pt: "Abaixo são os resultados do seu perfil:",
            },
            "best-wishes": {
                en: "Best wishes,",
                es: "Nuestros mejores deseos,",
                pt: "Atenciosamente,",
            },
            "radical-purpose-team": {
                en: "Radical Purpose Team",
                es: "Equipo Radical Purpose",
                pt: "Equipe Propósito Radical",
            },
            "all-of-the-radical-needs-are-present-in-us": {
                en: "All of the Radical Needs exist in us, regardless of the degree to which they are present and expressed. The Radical Needs Profile shows that a need can be higher or lower relative to another. For example, the person's need for autonomy may be higher than the need for safety and this does not mean that the person does not need safety. It also doesn't mean we have to have all needs high or low. There is no ideal profile — the result is a map to help people get to know themselves better.",
                es: "Todas las Necesidades Radicales existen en nosotros, independientemente del grado en que estén presentes y se expresen. El Perfil de Necesidades Radicales muestra que una necesidad puede ser más alta o más baja en relación con otra. Por ejemplo, la necesidad de autonomía de la persona puede ser más alta que la necesidad de seguridad y esto no significa que la persona no necesite seguridad. Tampoco significa que tengamos que tener altas o bajas todas las necesidades. No hay un perfil ideal - el resultado es un mapa para ayudar a las personas a conocerse mejor.",
                pt: "Todas as necessidades radicais existem em nós independentemente do grau que elas estão presentes e se expressam. O Perfil de Necessidades Radicais possibilita entender que necessidade é mais alta ou baixa em relação a outra.  Por exemplo, a necessidade de autonomia da pessoa pode ser mais alta do que a necessidade de segurança e isto não quer dizer que a pessoa não tenha necessidade de segurança. Também não quer dizer que temos que ter todas as necessidades altas ou baixas. Não existe um perfil ideal - o resultado é um mapa para ajudar as pessoas a se conhecerem melhor.",
            },
            "below-is-an-exercise-to-help-you-interpret": {
                en: "Below is an exercise to help you interpret your Radical Needs Profile.",
                es: "A continuación se presenta un ejercicio que te ayudará a interpretar tu Perfil de Necesidades Radicales.",
                pt: "Segue um exercício para te ajudar a interpretar o teu perfil de necessidades radicais.",
            },
            "pick-one-of-your-high-radical-needs": {
                en: "Pick one of your HIGH Radical Needs and answer these questions:",
                es: "Elige una de tus Necesidades Radicales ALTAS y responde a estas preguntas:",
                pt: "Selecione uma das necessidades com mais alto ranking e responda às perguntas:",
            },
            "what-behaviors-and-actions-in-your-daily-life": {
                en: "What behaviors and actions in your daily life express this need? Give examples.",
                es: "¿Qué comportamientos y acciones de tu vida diaria expresan esta necesidad? Da ejemplos.",
                pt: "Que comportamentos seus e ações do dia a dia expressam esta necessidade? Dê exemplos.",
            },
            "how-do-you-feel-when-the-need-is-met": {
                en: "How do you feel when the need is met?",
                es: "¿Cómo te sientes cuando se satisface la necesidad?",
                pt: "O que você sente quando esta necessidade é atendida?",
            },
            "how-do-you-feel-when-the-need-is-not-met": {
                en: "How do you feel when the need is not met?",
                es: "¿Cómo te sientes cuando la necesidad no se satisface?",
                pt: "O que você sente quando esta necessidade não é atendida?",
            }
        },

        // FIELDLABEL
       fieldLabel: {
            "name": {
                en: "Name",
                es: "Nombre",
                pt: "Nome"
            },
            "email": {
                en: "Email",
                es: "Correo Electrónico",
                pt: "Email"
            },
            "member-name": {
                en: "Member Name",
                es: "Nombre de Miembro",
                pt: "Nome do Membro"
            },
            "profile-code": {
                en: "Profile Code (optional)",
                es: "Código de Perfil (opcional)",
                pt: "Código do Perfil (opcional)"
            }
        },
        // PROMPT
        prompt: {
            "peace & quiet": {
                en: "peace & quiet",
                es: "paz y tranquilidad",
                pt: "ter paz e tranquilidade"
            },
            "resting & sleeping": {
                en: "resting & sleeping",
                es: "descansar y dormir",
                pt: "descansar e dormir"
            },
            "eating & drinking": {
                en: "eating & drinking",
                es: "comer y beber",
                pt: "comer e beber"
            },
            "exercising & sports": {
                en: "exercising & sports",
                es: "ejercitarse y deportes",
                pt: "praticar exercícios e esportes"
            },
            "music & dancing": {
                en: "music & dancing",
                es: "música y danza",
                pt: "ouvir música e dançar"
            },
            "being independent": {
                en: "being independent",
                es: "ser independiente",
                pt: "ser independente"
            },
            "exploring & discovering": {
                en: "exploring & discovering",
                es: "explorar y descubrir",
                pt: "explorar e descobrir"
            },
            "being safe & secure": {
                en: "being safe & secure",
                es: "estar a salvo y seguro",
                pt: "estar seguro e protegido"
            },
            "being funny & playful": {
                en: "being funny & playful",
                es: "divertirse y jugar",
                pt: "ser engraçado e brincalhão"
            },
            "making sense of things": {
                en: "making sense of things",
                es: "encontrar sentido a las cosas",
                pt: "encontrar o sentido das coisas"
            },
            "getting & having things": {
                en: "getting & having things",
                es: "conseguir y tener posesiones",
                pt: "conseguir e ter coisas"
            },
            "making & building things": {
                en: "making & building things",
                es: "crear y construir realizaciones",
                pt: "fazer e construir coisas"
            },
            "planning & organizing things": {
                en: "planning & organizing things",
                es: "planear y organizar realidades",
                pt: "planejar e organizar as coisas"
            },
            "changing & improving things": {
                en: "changing & improving things",
                es: "cambiar y mejorar las cosas",
                pt: "mudar e melhorar as coisas"
            },
            "helping other people": {
                en: "helping other people",
                es: "ayudar a otros",
                pt: "ajudar outras pessoas"
            },
            "admiring & respecting others": {
                en: "admiring & respecting others",
                es: "admirar y respetar a otros",
                pt: "admirar e respeitar outras pessoas"
            },
            "fitting in with people": {
                en: "fitting in with people",
                es: "adaptarse a otras personas",
                pt: "agradar a outras pessoas"
            },
            "being with other people": {
                en: "being with other people",
                es: "ser con otra personas",
                pt: "estar com outras pessoas"
            },
            "being accepted by others": {
                en: "being accepted by others",
                es: "ser aceptado por otras personas",
                pt: "ser aceito pelos outros"
            },
            "being caring & intimate": {
                en: "being caring & intimate",
                es: "ser cuidadoso e intimar con otras personas",
                pt: "ser acolhedor e íntimo"
            },
            "having things be fair": {
                en: "having things be fair",
                es: "cuidar que las cosas sean justas",
                pt: "tornar as coisas justas"
            },
            "standing up for myself": {
                en: "standing up for myself",
                es: "valerme por mí mismo",
                pt: "posicionar-me"
            },
            "being strong & in control": {
                en: "being strong & in control",
                es: "ser fuerte y estar en control",
                pt: "ser forte e estar no controle"
            },
            "being respected & admired": {
                en: "being respected & admired",
                es: "ser respetado y admirado",
                pt: "ser respeitado e admirado"
            },
            "gaining mastery & growing": {
                en: "gaining mastery & growing",
                es: "ganar maestría y crecer",
                pt: "crescer e obter maestria"
            }
        },
        // RADICALNEED
        radicalNeed: {
            "being": {
                en: "Being",
                es: "Ser",
                pt: "Ser"
            },
            "meaning": {
                en: "Meaning",
                es: "Significar",
                pt: "Significado"
            },
            "impacting": {
                en: "Impacting",
                es: "Impactar",
                pt: "Impacto"
            },
            "belonging": {
                en: "Belonging",
                es: "Pertenecer",
                pt: "Pertencimento"
            },
            "becoming": {
                en: "Becoming",
                es: "Devenir",
                pt: "Evolução"
            }
        },
        // RADICALNEEDDESC
        radicalNeedDesc: {
            "being": {
                en: "The Being Need motivates and regulates the body's physiological and stimulation needs. The higher this need is in you, the more likely you will be motivated to attend to your body's homeostatic levels and prioritize them over other aspects.",
                es: "La Necesidad de Ser motiva y regula las necesidades fisiológicas y de estimulación del organismo. Cuanto mayor sea esta necesidad en ti, más probabilidades tendrás de estar motivado para atender los niveles homeostáticos de tu cuerpo y priorizarlos sobre otros aspectos",
                pt: "A Necessidade de Ser motiva e regula as necessidades fisiológicas e os estímulos que o corpo recebe. Quanto maior essa necessidade estiver em você, maior a probabilidade de você estar motivado a atender aos níveis homeostáticos do seu corpo e priorizá-los em relação a outros aspectos."
            },
            "meaning": {
                en: "The Meaning Need motivates and regulates seeking the freedom needed to discover and learn. The higher this need is in you, the more likely you will be motivated to seek to play, explore, and make sense of things while staying safe and becoming attached to what has value and avoid what does not.",
                es: "La Necesidad de Significar motiva y regula la búsqueda de la libertad necesaria para descubrir y aprender. Cuanto mayor sea esta necesidad en ti, más probable será que estés motivado para buscar jugar, explorar y dar sentido a las cosas mientras te mantienes seguro y cuidas lo que tiene valor y evitas lo que no lo tiene",
                pt: "A Necessidade de Significado motiva e regula a busca da liberdade necessária para descobrir e aprender. Quanto maior essa necessidade estiver em você, maior a probabilidade de você estar motivado a procurar brincar, explorar e dar sentido às coisas enquanto se mantém seguro focando no que tem valor e evitando o que não tem."
            },
            "impacting": {
                en: "The Impacting Need motivates and regulates obtaining, changing, creating, and taking care of meaningful things. The higher this need is in you, the more likely you will be motivated to act and impact the people and things you value.",
                es: "La necesidad de Impactar motiva y regula la obtención, el cambio, la creación y el cuidado de cosas significativas. Cuanto mayor sea esta necesidad en ti, más probabilidades tendrás de estar motivado para actuar e impactar a las personas y las cosas que valoras.",
                pt: "A Necessidade de Impacto motiva e regula a obtenção, a mudança, a criação e o cuidado de coisas significativas. Quanto maior essa necessidade estiver em você, maior a probabilidade de você estar motivado a agir e impactar as pessoas e coisas que você valoriza."
            },
            "belonging": {
                en: "The Belonging Need motivates and regulates seeking, establishing, conforming, and maintaining social relationships with those you share similar meaning and impact. The higher this need is in you, the more likely you will be motivated to follow people you are attached to and socialize with those you find meaningful.",
                es: "La Necesidad de Pertenecer motiva y regula la búsqueda, el establecimiento, la conformidad y el mantenimiento de relaciones sociales con aquellos con quienes compartes un significado e impacto similar. Cuanto mayor sea esta necesidad en ti, más probabilidades tendrás de estar motivado para seguir a las personas a las que estás apegado y socializar con las que consideras significativas",
                pt: "A Necessidade de Pertencimento motiva e regula a busca, o estabelecimento, a adaptação e a manutenção de relacionamentos sociais com aqueles com quem você compartilha significados e impactos semelhantes. Quanto maior essa necessidade estiver em você, maior a probabilidade de você se motivar a estar com pessoas com as quais você se sente conectado e a socializar com aquelas que considera significativas."
            },
            "becoming": {
                en: "The Becoming Need motivates and regulates the tracking of your and others' behavior towards one another and your accomplishments and personal growth. The higher this need is in you, the more likely you will be motivated to be a vigilant observer and evaluator of yourself and others.",
                es: "La Necesidad de Devenir motiva y regula el seguimiento de tu comportamiento y el de los otros hacia los logros y crecimiento personal tuyo y de los demás. Cuanto más elevada sea esta necesidad en ti, más probable será que estés motivado a ser un atento observador y evaluador de ti mismo y de los demás.",
                pt: "A Necessidade de Evolução motiva e regula o acompanhamento do seu comportamento e o dos outros em relação uns aos outros, das suas realizações e do seu crescimento pessoal. Quanto maior essa necessidade estiver em você, maior a probabilidade de você ser motivado a ser um observador vigilante e avaliador de si mesmo e dos outros."
            }
        },
        // RADICALNEEDFUNCTION
        radicalNeedFunction: {
            "being": {
                en: "Maintain Body",
                es: "Cuidar el Cuerpo",
                pt: "Cuidar do corpo"
            },
            "meaning": {
                en: "Assess Value",
                es: "Dar valor",
                pt: "Dar valor"
            },
            "impacting": {
                en: "Effect Change",
                es: "Efectuar cambios",
                pt: "Realizar mudanças"
            },
            "belonging": {
                en: "Gain Relations",
                es: "Fortalecer Relaciones",
                pt: "Fortalecer as Relações"
            },
            "becoming": {
                en: "Assess Efficacy",
                es: "Evaluar Eficacia",
                pt: "Avaliar Eficácia"
            },
        },
        // NEED
        need: {
            "being": {
                en: "Being",
                es: "Ser",
                pt: "Ser"
            },
            "meaning": {
                en: "Meaning",
                es: "Significar",
                pt: "Significado"
            },
            "impacting": {
                en: "Impacting",
                es: "Impactar",
                pt: "Impacto"
            },
            "belonging": {
                en: "Belonging",
                es: "Pertenecer",
                pt: "Pertencimento"
            },
            "becoming": {
                en: "Becoming",
                es: "Devenir",
                pt: "Evolução"
            },
            "relax": {
                en: "Relax",
                es: "Relajar",
                pt: "Relaxar"
            },
            "sleep": {
                en: "Sleep",
                es: "Dormir",
                pt: "Dormir"
            },
            "nourish": {
                en: "Nourish",
                es: "Nutrir",
                pt: "Nutrir"
            },
            "move": {
                en: "Move",
                es: "Mover",
                pt: "Movimento"
            },
            "rhythm": {
                en: "Rhythm",
                es: "Ritmo",
                pt: "Ritmo"
            },
            "autonomy": {
                en: "Autonomy",
                es: "Autonomía",
                pt: "Autonomia"
            },
            "curiosity": {
                en: "Curiosity",
                es: "Curiosidad",
                pt: "Curiosidade"
            },
            "safety": {
                en: "Safety",
                es: "Seguridad",
                pt: "Segurança"
            },
            "play": {
                en: "Play",
                es: "Jugar",
                pt: "Diversão"
            },
            "sense-making": {
                en: "Sense-Making",
                es: "Dar Sentido",
                pt: "Encontrar sentido"
            },
            "acquire": {
                en: "Acquire",
                es: "Adquirir",
                pt: "Adquirir"
            },
            "create": {
                en: "Create",
                es: "Crear",
                pt: "Criar"
            },
            "order": {
                en: "Order",
                es: "Ordenar",
                pt: "Organização"
            },
            "impact": {
                en: "Impact",
                es: "Impacto",
                pt: "Impactar"
            },
            "nurture": {
                en: "Nurture",
                es: "Sustentar",
                pt: "Cuidar"
            },
            "admire": {
                en: "Admire",
                es: "Admirar",
                pt: "Admirar"
            },
            "conform": {
                en: "Conform",
                es: "Adaptar",
                pt: "Adaptar"
            },
            "connect": {
                en: "Connect",
                es: "Conectar",
                pt: "Conectar"
            },
            "belong": {
                en: "Belong",
                es: "Pertenecer a",
                pt: "Pertencer"
            },
            "intimacy": {
                en: "Intimacy",
                es: "Intimidad",
                pt: "Intimidade"
            },
            "fairness": {
                en: "Fairness",
                es: "Justicia",
                pt: "Equidade"
            },
            "retaliate": {
                en: "Retaliate",
                es: "Resarcir",
                pt: "Retaliar"
            },
            "dominate": {
                en: "Dominate",
                es: "Dominio",
                pt: "Dominar"
            },
            "status": {
                en: "Status",
                es: "Estatus",
                pt: "Status"
            },
            "become": {
                en: "Become",
                es: "Convertirse en",
                pt: "Evolução"
            },
            "acceptance": {
                en: "acceptance",
                es: "aceptación",
                pt: "aceitação"
            },
            "achievement": {
                en: "achievement",
                es: "logro",
                pt: "realização"
            },
            "adventure": {
                en: "adventure",
                es: "aventuras",
                pt: "aventura"
            },
            "affection": {
                en: "affection",
                es: "afecto",
                pt: "afeição"
            },
            "air": {
                en: "air",
                es: "aire",
                pt: "ar"
            },
            "authenticity": {
                en: "authenticity",
                es: "autenticidad",
                pt: "autenticidade"
            },
            "avoid danger": {
                en: "avoid danger",
                es: "evitar el peligro",
                pt: "evitar o perigo"
            },
            "avoid harm": {
                en: "avoid harm",
                es: "evitar daño",
                pt: "evitar danos"
            },
            "avoid risk": {
                en: "avoid risk",
                es: "evitar el riesgo",
                pt: "evitar riscos"
            },
            "balance": {
                en: "balance",
                es: "equilibrio",
                pt: "equilibrar"
            },
            "beauty": {
                en: "beauty",
                es: "belleza",
                pt: "beleza"
            },
            "bonding": {
                en: "bonding",
                es: "unión",
                pt: "ligando"
            },
            "breath": {
                en: "breath",
                es: "aliento",
                pt: "respiração"
            },
            "care": {
                en: "care",
                es: "cuidado",
                pt: "cuidado"
            },
            "caring": {
                en: "caring",
                es: "cuidando",
                pt: "carinhoso"
            },
            "catnap": {
                en: "catnap",
                es: "siestecita",
                pt: "cochilo"
            },
            "celebrate loss": {
                en: "celebrate loss",
                es: "celebrar la pérdida",
                pt: "comemorar a perda"
            },
            "celebrate dreams fulfilled": {
                en: "celebrate dreams fulfilled",
                es: "celebrar los sueños cumplidos",
                pt: "celebrar sonhos realizados"
            },
            "celebrate life": {
                en: "celebrate life",
                es: "celebra la vida",
                pt: "celebrar a vida"
            },
            "celebration": {
                en: "celebration",
                es: "celebracion",
                pt: "celebração"
            },
            "challenge": {
                en: "challenge",
                es: "desafío",
                pt: "desafio"
            },
            "choice": {
                en: "choice",
                es: "elección",
                pt: "escolha"
            },
            "clarity": {
                en: "clarity",
                es: "claridad",
                pt: "clareza"
            },
            "closeness": {
                en: "closeness",
                es: "cercanía",
                pt: "proximidade"
            },
            "collaboration": {
                en: "collaboration",
                es: "colaboración",
                pt: "colaboração"
            },
            "comfort": {
                en: "comfort",
                es: "comodidad",
                pt: "conforto"
            },
            "communion": {
                en: "communion",
                es: "comunión",
                pt: "comunhão"
            },
            "competence": {
                en: "competence",
                es: "competencia",
                pt: "competência"
            },
            "comprehend": {
                en: "comprehend",
                es: "comprender",
                pt: "compreender"
            },
            "confidence": {
                en: "confidence",
                es: "confianza",
                pt: "confiança"
            },
            "comforted": {
                en: "comforted",
                es: "consolado",
                pt: "confortado"
            },
            "connection": {
                en: "connection",
                es: "conexión",
                pt: "conexão"
            },
            "consistency": {
                en: "consistency",
                es: "consistencia",
                pt: "consistência"
            },
            "contribution": {
                en: "contribution",
                es: "contribución",
                pt: "contribuição"
            },
            "control": {
                en: "control",
                es: "control",
                pt: "ao controle"
            },
            "cooperation": {
                en: "cooperation",
                es: "cooperación",
                pt: "cooperação"
            },
            "creativity": {
                en: "creativity",
                es: "creatividad",
                pt: "criatividade"
            },
            "dance": {
                en: "dance",
                es: "danza",
                pt: "dança"
            },
            "development": {
                en: "development",
                es: "desarrollo",
                pt: "desenvolvimento"
            },
            "discovery": {
                en: "discovery",
                es: "descubrimiento",
                pt: "descoberta"
            },
            "doing": {
                en: "doing",
                es: "haciendo",
                pt: "fazendo"
            },
            "dominance": {
                en: "dominance",
                es: "dominio",
                pt: "domínio"
            },
            "dry": {
                en: "dry",
                es: "seco",
                pt: "seco"
            },
            "eat": {
                en: "eat",
                es: "come",
                pt: "comer"
            },
            "efficiency": {
                en: "efficiency",
                es: "eficiencia",
                pt: "eficiência"
            },
            "emotional safety": {
                en: "emotional safety",
                es: "seguridad emocional",
                pt: "segurança emocional"
            },
            "enrichment of life": {
                en: "enrichment of life",
                es: "enriquecimiento de la vida",
                pt: "enriquecimento da vida"
            },
            "equality": {
                en: "equality",
                es: "igualdad",
                pt: "igualdade"
            },
            "esthetics": {
                en: "esthetics",
                es: "estética",
                pt: "estética"
            },
            "exercise": {
                en: "exercise",
                es: "ejercicio",
                pt: "exercício"
            },
            "exploration": {
                en: "exploration",
                es: "exploración",
                pt: "exploração"
            },
            "explore": {
                en: "explore",
                es: "explorar",
                pt: "explorar"
            },
            "familiarity": {
                en: "familiarity",
                es: "familiaridad",
                pt: "familiaridade"
            },
            "fellowship": {
                en: "fellowship",
                es: "compañerismo",
                pt: "companheirismo"
            },
            "food": {
                en: "food",
                es: "comida",
                pt: "comida"
            },
            "freedom": {
                en: "freedom",
                es: "libertad",
                pt: "liberdade"
            },
            "fresh air": {
                en: "fresh air",
                es: "aire fresco",
                pt: "ar fresco"
            },
            "fun": {
                en: "fun",
                es: "divertida",
                pt: "diversão"
            },
            "get even": {
                en: "get even",
                es: "obtener incluso",
                pt: "ficar quites"
            },
            "growth": {
                en: "growth",
                es: "crecimiento",
                pt: "crescimento"
            },
            "harmony": {
                en: "harmony",
                es: "armonía",
                pt: "harmonia"
            },
            "health": {
                en: "health",
                es: "salud",
                pt: "saúde"
            },
            "home": {
                en: "home",
                es: "casa",
                pt: "casa"
            },
            "honesty": {
                en: "honesty",
                es: "honestidad",
                pt: "honestidade"
            },
            "honor": {
                en: "honor",
                es: "honor",
                pt: "honra"
            },
            "humor": {
                en: "humor",
                es: "humor",
                pt: "humor"
            },
            "hydration": {
                en: "hydration",
                es: "hidratación",
                pt: "hidratação"
            },
            "importance": {
                en: "importance",
                es: "importancia",
                pt: "importância"
            },
            "inclusion": {
                en: "inclusion",
                es: "inclusión",
                pt: "inclusão"
            },
            "independence": {
                en: "independence",
                es: "independencia",
                pt: "independência"
            },
            "information": {
                en: "information",
                es: "información",
                pt: "informações"
            },
            "initiative": {
                en: "initiative",
                es: "iniciativa",
                pt: "iniciativa"
            },
            "innovate": {
                en: "innovate",
                es: "innovar",
                pt: "inovar"
            },
            "innovation": {
                en: "innovation",
                es: "innovación",
                pt: "inovação"
            },
            "inspiration": {
                en: "inspiration",
                es: "inspiración",
                pt: "inspiração"
            },
            "integrity": {
                en: "integrity",
                es: "integridad",
                pt: "integridade"
            },
            "interdependence": {
                en: "interdependence",
                es: "interdependencia",
                pt: "interdependência"
            },
            "investigate": {
                en: "investigate",
                es: "investigar",
                pt: "investigar"
            },
            "kindness": {
                en: "kindness",
                es: "amabilidad",
                pt: "gentileza"
            },
            "learn": {
                en: "learn",
                es: "aprender",
                pt: "aprender"
            },
            "learning": {
                en: "learning",
                es: "aprendiendo",
                pt: "aprendendo"
            },
            "mentor": {
                en: "mentor",
                es: "mentor",
                pt: "mentor"
            },
            "mess around": {
                en: "mess around",
                es: "perder el tiempo",
                pt: "perder tempo"
            },
            "money": {
                en: "money",
                es: "dinero",
                pt: "dinheiro"
            },
            "movement": {
                en: "movement",
                es: "movimiento",
                pt: "movimento"
            },
            "music": {
                en: "music",
                es: "música",
                pt: "música"
            },
            "mutuality": {
                en: "mutuality",
                es: "mutualidad",
                pt: "mutualidade"
            },
            "mystery": {
                en: "mystery",
                es: "misterio",
                pt: "mistério"
            },
            "nap": {
                en: "nap",
                es: "siesta",
                pt: "sesta"
            },
            "nourishment": {
                en: "nourishment",
                es: "alimento",
                pt: "nutrição"
            },
            "nurturing": {
                en: "nurturing",
                es: "crianza",
                pt: "nutrir"
            },
            "nutrition": {
                en: "nutrition",
                es: "nutrición",
                pt: "nutrição"
            },
            "openness": {
                en: "openness",
                es: "franqueza",
                pt: "franqueza"
            },
            "oxygen": {
                en: "oxygen",
                es: "oxígeno",
                pt: "oxigênio"
            },
            "physical safety": {
                en: "physical safety",
                es: "seguridad física",
                pt: "segurança física"
            },
            "power": {
                en: "power",
                es: "el poder",
                pt: "o poder"
            },
            "predictability": {
                en: "predictability",
                es: "previsibilidad",
                pt: "previsibilidade"
            },
            "presence": {
                en: "presence",
                es: "presencia",
                pt: "presença"
            },
            "progress": {
                en: "progress",
                es: "progreso",
                pt: "progresso"
            },
            "protection from harm": {
                en: "protection from harm",
                es: "protección contra daños",
                pt: "proteção contra danos"
            },
            "pushback": {
                en: "pushback",
                es: "hacer retroceder",
                pt: "retrocesso"
            },
            "reciprocity": {
                en: "reciprocity",
                es: "reciprocidad",
                pt: "reciprocidade"
            },
            "recreation": {
                en: "recreation",
                es: "recreación",
                pt: "lazer"
            },
            "relaxation": {
                en: "relaxation",
                es: "relajación",
                pt: "relaxamento"
            },
            "resources": {
                en: "resources",
                es: "recursos",
                pt: "recursos"
            },
            "respect": {
                en: "respect",
                es: "respeto",
                pt: "respeito"
            },
            "respond": {
                en: "respond",
                es: "responder",
                pt: "responder"
            },
            "rest": {
                en: "rest",
                es: "descansar",
                pt: "descanso"
            },
            "revenge": {
                en: "revenge",
                es: "venganza",
                pt: "vingança"
            },
            "run": {
                en: "run",
                es: "correr",
                pt: "correr"
            },
            "safe": {
                en: "safe",
                es: "a salvo",
                pt: "seguro"
            },
            "security": {
                en: "security",
                es: "seguridad",
                pt: "segurança"
            },
            "self-acceptance": {
                en: "self-acceptance",
                es: "auto-aceptación",
                pt: "auto aceitação"
            },
            "self-care": {
                en: "self-care",
                es: "autocuidado",
                pt: "cuidados pessoais"
            },
            "self-connection": {
                en: "self-connection",
                es: "autoconexión",
                pt: "auto-conexão"
            },
            "self-esteem": {
                en: "self-esteem",
                es: "autoestima",
                pt: "auto estima"
            },
            "self-respect": {
                en: "self-respect",
                es: "respeto a ti mismo",
                pt: "auto-respeito"
            },
            "self-worth": {
                en: "self-worth",
                es: "autoestima",
                pt: "autovalorização"
            },
            "sex": {
                en: "sex",
                es: "sexo",
                pt: "sexo"
            },
            "sexual": {
                en: "sexual",
                es: "sexual",
                pt: "sexual"
            },
            "sexual expression": {
                en: "sexual expression",
                es: "expresiones sexuales",
                pt: "expressão sexual"
            },
            "shelter": {
                en: "shelter",
                es: "abrigo",
                pt: "abrigo"
            },
            "siesta": {
                en: "siesta",
                es: "siesta",
                pt: "siesta"
            },
            "solidarity": {
                en: "solidarity",
                es: "solidaridad",
                pt: "solidariedade"
            },
            "space": {
                en: "space",
                es: "espacio",
                pt: "espaço"
            },
            "spontaneity": {
                en: "spontaneity",
                es: "espontaneidad",
                pt: "espontaneidade"
            },
            "stability": {
                en: "stability",
                es: "estabilidad",
                pt: "estabilidade"
            },
            "standup for myself": {
                en: "standup for myself",
                es: "defenderme",
                pt: "stand up para mim mesmo"
            },
            "structure": {
                en: "structure",
                es: "estructura",
                pt: "estrutura"
            },
            "sunlight": {
                en: "sunlight",
                es: "luz de sol",
                pt: "luz solar"
            },
            "support": {
                en: "support",
                es: "apoyo",
                pt: "suporte"
            },
            "survival": {
                en: "survival",
                es: "supervivencia",
                pt: "sobrevivência"
            },
            "sustenance": {
                en: "sustenance",
                es: "sustento",
                pt: "sustento"
            },
            "take charge": {
                en: "take charge",
                es: "hacerse cargo",
                pt: "assumir o comando"
            },
            "tenderness": {
                en: "tenderness",
                es: "sensibilidad",
                pt: "ternura"
            },
            "to acquire": {
                en: "to acquire",
                es: "adquirir",
                pt: "adquirir"
            },
            "to be heard": {
                en: "to be heard",
                es: "ser escuchado",
                pt: "ser ouvido"
            },
            "to be known": {
                en: "to be known",
                es: "ser conocido",
                pt: "ser conhecido"
            },
            "to be left alone": {
                en: "to be left alone",
                es: "ser dejado solo",
                pt: "ser deixado sozinho"
            },
            "to be loved": {
                en: "to be loved",
                es: "ser amado",
                pt: "ser amado"
            },
            "to be respected": {
                en: "to be respected",
                es: "ser respetado",
                pt: "ser respeitado"
            },
            "to be seen": {
                en: "to be seen",
                es: "ser visto",
                pt: "ser visto"
            },
            "to be trusted": {
                en: "to be trusted",
                es: "ser de confianza",
                pt: "ser confiável"
            },
            "to belong": {
                en: "to belong",
                es: "pertenecer",
                pt: "pertencer"
            },
            "to breath": {
                en: "to breath",
                es: "respirar",
                pt: "respirar"
            },
            "to buy": {
                en: "to buy",
                es: "comprar",
                pt: "comprar"
            },
            "to contribute": {
                en: "to contribute",
                es: "contribuir",
                pt: "contribuir"
            },
            "to eat": {
                en: "to eat",
                es: "comer",
                pt: "comer"
            },
            "to find out": {
                en: "to find out",
                es: "descubrir",
                pt: "descobrir"
            },
            "to have": {
                en: "to have",
                es: "tener",
                pt: "ter"
            },
            "to know": {
                en: "to know",
                es: "saber",
                pt: "saber"
            },
            "to matter": {
                en: "to matter",
                es: "importar",
                pt: "importar"
            },
            "to move": {
                en: "to move",
                es: "para mover",
                pt: "mover"
            },
            "to own": {
                en: "to own",
                es: "poseer",
                pt: "possuir"
            },
            "to possess": {
                en: "to possess",
                es: "poseer",
                pt: "possuir"
            },
            "touch": {
                en: "touch",
                es: "tocar",
                pt: "tocar"
            },
            "transparency": {
                en: "transparency",
                es: "transparencia",
                pt: "transparência"
            },
            "trust": {
                en: "trust",
                es: "confianza",
                pt: "confiar em"
            },
            "trusting": {
                en: "trusting",
                es: "confiando",
                pt: "confiando"
            },
            "understanding": {
                en: "understanding",
                es: "comprensión",
                pt: "compreensão"
            },
            "vengeance": {
                en: "vengeance",
                es: "venganza",
                pt: "vingança"
            },
            "walk": {
                en: "walk",
                es: "caminar",
                pt: "andar"
            },
            "warmth": {
                en: "warmth",
                es: "calor",
                pt: "calor"
            },
            "water": {
                en: "water",
                es: "agua",
                pt: "agua"
            },
            "wellness": {
                en: "wellness",
                es: "bienestar",
                pt: "bem estar"
            },
            "wholeness": {
                en: "wholeness",
                es: "integridad",
                pt: "integridade"
            },
            "workout": {
                en: "workout",
                es: "ejercicio",
                pt: "treino"
            },
            "being-other": {
                en: "Being-Other",
                es: "Ser-Otra",
                pt: "Ser-Outro"
            },
            "meaning-other": {
                en: "Meaning-Other",
                es: "Significar-Otra",
                pt: "Significar-Outro"
            },
            "impacting-other": {
                en: "Impacting",
                es: "Impactar-Otra",
                pt: "Impactar-Outro"
            },
            "belonging-other": {
                en: "Belonging",
                es: "Pertenecer-Otra",
                pt: "Pertenecer-Outro"
            },
            "becoming-other": {
                en: "Becoming-Other",
                es: "Devenir-Otra",
                pt: "Evolução-Outro"
            }
        },
        // NEEDDESC
        needDesc: {
            "relax": {
                en: "The degree to which you are motivated to seek time to unwind, reflect or commune with nature. The higher the Relax aspect is in you, the more it motivates you to avoid physical activity when your body is overstimulated.",
                es: "El grado en el que estás motivado para buscar tiempo para relajarte, reflexionar o estar en comunión con la naturaleza. Cuanto más alto es el aspecto Relajar en ti, más te motiva a evitar la actividad física cuando tu cuerpo está sobreestimulado.",
                pt: "O grau em que você está motivado a buscar tempo para relaxar, refletir ou comungar com a natureza. Quanto mais alto o aspecto Relaxar estiver em você, mais ele o motiva a evitar a atividade física quando seu corpo está superestimulado"
            },
            "sleep": {
                en: "The degree to which you are motivated to restore your body's equilibrium, feeling rested and ready for whatever may be coming our way. The higher the Sleep aspect is in you, the more it motivates you to recognize and respect your body's need to sleep.",
                es: "El grado en el que estás motivado para restaurar el equilibrio de tu cuerpo, sintiéndote descansado y listo para lo que se presente. Cuanto mayor sea el aspecto Dormir en ti, más te motivará a reconocer y respetar la necesidad de dormir que tiene tu cuerpo.",
                pt: "O grau em que você está motivado para restaurar o equilíbrio do seu corpo, sentindo-se descansado e pronto para o que quer que venha em nosso caminho. Quanto mais alto o aspecto do sono estiver em você, mais ele o motiva a reconhecer e respeitar a necessidade do seu corpo de dormir."
            },
            "nourish": {
                en: "The degree to which you are motivated to seek the foods and beverages your body needs. The higher the Nourish aspect is in you, the more it motivates you to focus on the opportunity to take in nutrient-rich food and beverages.",
                es: "El grado en que estás motivado a buscar los alimentos y bebidas que tu cuerpo necesita. Cuanto más alto sea el aspecto Nutrir en ti, más te motivará a concentrarte en la oportunidad de consumir alimentos y bebidas saludables y ricos en nutrientes",
                pt: "O grau em que você está motivado a buscar os alimentos e bebidas que seu corpo precisa. Quanto maior o aspecto Nutrir em você, mais ele o motiva a se concentrar na oportunidade de ingerir alimentos e bebidas ricos em nutrientes."
            },
            "move": {
                en: "The degree to which you are motivated to engage in physical activity and to participate in activities that offer the opportunity for improving repetitive actions. The higher the Move aspect is in you, the more it motivates you to seek to walk, exercise, and participate in sports.",
                es: "El grado en el que estás motivado para realizar actividad física y participar en actividades que ofrecen la oportunidad de mejorar las acciones repetitivas. Cuanto más alto es el aspecto Mover en ti, más te motiva a buscar caminar, hacer ejercicio y participar en deportes.",
                pt: "O grau em que você está motivado para praticar atividade física e participar de atividades que oferecem a oportunidade de melhorar ações repetitivas. Quanto mais alto o aspecto Movimento estiver em você, mais ele o motiva a buscar caminhar, se exercitar e participar de esportes."
            },
            "rhythm": {
                en: "The degree to which you are motivated to move and listen to rhythms in your body and the outside world. The higher the Rhythm aspect is in you, the more you resonate with the sound of music and feel the need to dance.",
                es: "El grado en el que estás motivado para moverte y escuchar los ritmos de tu cuerpo y del mundo exterior. Cuanto más alto sea el aspecto Ritmo en ti, más resonarás con el sonido de la música y sentirás la necesidad de bailar.",
                pt: "O grau em que você está motivado para se mover e ouvir os ritmos do seu corpo e do mundo exterior. Quanto mais alto o aspecto do Ritmo estiver em você, mais você ressoará com o som da música e sentirá a necessidade de dançar."
            },
            "autonomy": {
                en: "The degree to which you are motivated to seek freedom and space to think, decide, and act in a self-determined way. The higher the Autonomy aspect is in you, the more it motivates you to avoid, resist, and escape others' control.",
                es: "El grado en el que estás motivado para buscar libertad y espacio para pensar, decidir y actuar de manera autodeterminada. Cuanto más alto es el aspecto  Autonomía en ti, más te motiva a evitar, resistir y escapar del control de los demás.",
                pt: "O grau em que você está motivado a buscar liberdade e espaço para pensar, decidir e agir de maneira autodeterminada. Quanto mais alto o aspecto Autonomia estiver em você, mais ele o motiva a evitar, resistir e fugir do controle dos outros."
            },
            "curiosity": {
                en: "The degree to which you are motivated to explore and make discoveries in contexts that have meaning to you or others you respect. The higher the Curiosity aspect is in you, the more it motivates you to take the time and risks solely for the experience and discovery.",
                es: "El grado en el que estás motivado para explorar y hacer descubrimientos en contextos que tienen significado para ti o para otras personas a las que respetas. Cuanto mayor sea el aspecto Curiosidad en ti, más te motivará a tomarte el tiempo y los riesgos únicamente para la experiencia y el descubrimiento.",
                pt: "O grau em que você está motivado a explorar e fazer descobertas em contextos que têm significado para você ou para outras pessoas que você respeita. Quanto mais alto o aspecto Curiosidade estiver em você, mais ele o motiva a dedicar tempo e assumir riscos apenas para viver a experiência e a descoberta."
            },
            "safety": {
                en: "The degree to which you are motivated to avoid engaging in risky environments and situations. The higher the Safety aspect is in you, the more it motivates you to avoid physical or social/professional situations it predicts might be dangerous.",
                es: "El grado en el que estás motivado para evitar involucrarte en entornos y situaciones de riesgo. Cuanto más alto sea el aspecto Seguridad en ti, más te motivará a evitar situaciones físicas o sociales / profesionales que predices o intuyes que pueden ser peligrosas",
                pt: "O grau em que você está motivado para evitar se envolver em ambientes e situações de risco. Quanto maior o aspecto Segurança estiver em você, mais o motiva a evitar situações físicas ou sociais/profissionais que possam ser previstas como perigosas."
            },
            "play": {
                en: "The degree to which you are motivated to explore your physical, social, and intellectual boundaries. The higher the Play aspect is in you, the more it motivates you to seek opportunities to engage in games, play, and other activities solely for the sake of the activity.",
                es: "El grado en que estás motivado para explorar tus límites físicos, sociales e intelectuales. Cuanto más alto sea el aspecto Juego en ti, más te motivará a buscar oportunidades para participar en experiencias, juegos y otras actividades únicamente por el gusto de la actividad.",
                pt: "O grau em que você está motivado a explorar seus limites físicos, sociais e intelectuais. Quanto mais alto o aspecto Diversão em você, mais ele o motiva a buscar oportunidades para se envolver em jogos, brincadeiras e outras atividades apenas para viver a atividade."
            },
            "sense-making": {
                en: "The degree to which you are motivated to ask why and to seek answers to open questions. The higher the Sense-Making aspect is in you, the more it motivates you to find compelling answers and to become attached to what is most meaningful.",
                es: "El grado en el que estás motivado para preguntar por qué y buscar respuestas a preguntas abiertas. Cuanto mayor sea el aspecto Dar Sentido en ti, más te motivará a encontrar respuestas convincentes y apegarte a lo que es más significativo.",
                pt: "O grau em que você está motivado a perguntar o porquê e a buscar respostas para perguntas abertas. Quanto mais elevado for o aspecto Encontrar Sentido em você, mais ele o motiva a encontrar respostas convincentes e a se envolver com o que é mais significativo."
            },
            "acquire": {
                en: "The degree to which you are motivated to acquire and possess things you or others value. The higher the Acquire aspect is in you, the more it motivates you to buy, own, and have what you find meaningful.",
                es: "El grado en el que estás motivado para adquirir y poseer cosas que tú u otros valoran. Cuanto más alto sea el aspecto Adquirir en ti, más te motivará a consumir,comprar, poseer y tener lo que consideras significativo.",
                pt: "O grau em que você está motivado a adquirir e possuir coisas que você ou outros valorizam. Quanto maior o aspecto Adquirir em você, mais ele o motiva a comprar, possuir e ter o que considera significativo."
            },
            "create": {
                en: "The degree to which you are motivated to create new things in the world that impact your life and the lives of others. The higher the Create aspect is in you, the more it motivates you to imagine, invent, design, and build things you and others find meaningful.",
                es: "El grado en que estás motivado para crear cosas nuevas en el mundo que impactan tu vida y la vida de los demás. Cuanto más elevado sea el aspecto Crear en ti, más te motivará a imaginar, inventar, diseñar, innovar y construir cosas que tú y los demás encuentran significativas.",
                pt: "O grau em que você está motivado a criar coisas novas no mundo que impactam sua vida e a vida dos outros. Quanto maior o aspecto Criar em você, mais ele o motiva a imaginar, inventar, projetar e construir coisas que você e os outros acham significativas."
            },
            "order": {
                en: "The degree to which you are motivated to make plans for future actions and events, and to bring order to physical things in the world. The higher the Order aspect is in you, the more it motivates you to organize your ideas, activities, events and environment.",
                es: "El grado en que estás motivado para hacer planes para acciones y eventos futuros, y para poner orden en las cosas físicas del mundo. Cuanto más alto es el aspecto Orden en ti, más te motiva a organizar tus ideas, actividades, eventos y entorno.",
                pt: "O grau em que você está motivado para fazer planos para ações e eventos futuros e para trazer ordem às coisas físicas do mundo. Quanto mais alto o aspecto Organização estiver em você, mais ele o motiva a organizar suas ideias, atividades, eventos e o ambiente."
            },
            "impact": {
                en: "The degree to which you are motivated to change and improve the state of things. The higher the Impact aspect is in you, the more it motivates you to make things different by altering them and making them better.",
                es: "El grado en el que estás motivado para cambiar y mejorar el estado de las cosas. Cuanto mayor sea el aspecto Impacto en ti, más te motivará a hacer las cosas diferentes al modificarlas y mejorarlas.",
                pt: "O grau em que você está motivado para mudar e melhorar o estado das coisas. Quanto maior o aspecto Impactar em você, mais ele o motiva a fazer as coisas diferentes, alterando-as e tornando-as melhores."
            },
            "nurture": {
                en: "The degree to which you are motivated to help others. The higher the Nurture aspect is in you, the more it motivates you to support and care for others based on perception of their needs.",
                es: "El grado en el que estás motivado para ayudar a los demás. Cuanto más alto sea el aspecto Sustentar en ti, más te motivará a apoyar y cuidar a los demás en función de la percepción de sus necesidades.",
                pt: "O grau em que você está motivado para ajudar os outros. Quanto maior o aspecto Cuidar em você, mais ele o motiva a apoiar e cuidar dos outros com base na percepção de suas necessidades."
            },
            "admire": {
                en: "The degree to which you are motivated to recognize and emulate others you perceive as admirable. The higher the Admire aspect is in you, the more it motivates you to pay attention to and emulate those assessed to be worthy of admiration.",
                es: "El grado en el que estás motivado para reconocer y emular a otros que percibes como admirables. Cuanto más alto es el aspecto Admirar en ti, más te motiva a prestar atención y emular a aquellos que consideras dignos de admiración.",
                pt: "O grau em que você está motivado a reconhecer outros que você considera admiráveis e se inspirar neles. Quanto maior o aspecto Admirar em você, mais ele o motiva a prestar atenção e se inspirar naqueles avaliados como dignos de admiração."
            },
            "conform": {
                en: "The degree to which you are motivated to act in accordance with the social norms established by the groups you seek to belong to. The higher the Conform aspect is in you, the more it motivates you to adapt to the established and accepted norms of the group.",
                es: "El grado en el que estás motivado para actuar de acuerdo con las normas sociales establecidas por los grupos a los que buscas pertenecer. Cuanto más alto es el aspecto Adaptar en ti, más te motiva el adaptarte a las normas establecidas y aceptadas del grupo.",
                pt: "O grau em que você está motivado a agir de acordo com as normas sociais estabelecidas pelos grupos aos quais você procura pertencer. Quanto mais alto o aspecto Adaptação estiver em você, mais ele o motiva a se adaptar às normas estabelecidas e aceitas pelo grupo."
            },
            "connect": {
                en: "The degree to which you are motivated to seek and be in the presence of others and to actively engage with them. The higher the Connect aspect is in you, the more it motivates you to identify others, reach out to them, and engage them through shared interests and values.",
                es: "El grado en el que estás motivado para buscar y estar en presencia de otros y para comprometerse activamente con ellos. Cuanto más alto es el aspecto Conectar en ti, más te motiva a identificar a los demás, acercarte a ellos, involucrarte e involucrarles a través de intereses y valores compartidos.",
                pt: "O grau em que você está motivado a procurar e estar na presença de outras pessoas e a se envolver ativamente com elas. Quanto mais alto o aspecto Conectar estiver em você, mais ele o motiva a buscar os outros, alcançá-los e engajá-los por meio de interesses e valores compartilhados."
            },
            "belong": {
                en: "The degree to which you are motivated to seek the acceptance and inclusion by groups you want to be part of. The higher the Belong aspect is in you, the more it motivates you to seek groups that will accept and support you and value your contributions.",
                es: "El grado en el que estás motivado para buscar la aceptación e inclusión en los grupos de los que quieres formar parte. Cuanto más alto es el aspecto  Pertenecer a en ti, más te motiva a buscar grupos que te acepten, apoyen y valoren tus contribuciones.",
                pt: "O grau em que você está motivado a buscar a aceitação e inclusão pelos grupos dos quais deseja fazer parte. Quanto mais alto o aspecto Pertencer em você, mais ele o motiva a buscar grupos que o aceitem, o apoiem e valorizem suas contribuições."
            },
            "intimacy": {
                en: "The degree to which you are motivated to have deep, meaningful, and intimate relationships with others. The higher the Intimacy aspect is in you, the more it motivates you to seek out those you resonate with and who resonate with you.",
                es: "El grado en el que estás motivado para tener relaciones profundas, significativas e íntimas con los demás. Cuanto más alto es el aspecto Intimidad en ti, más te motiva a buscar a aquellos con quienes resuenas y que resuenan contigo.",
                pt: "O grau em que você está motivado a ter relacionamentos profundos, significativos e íntimos com os outros. Quanto mais alto o aspecto Intimidade estiver em você, mais o motiva a procurar aqueles com quem você se conecta e que se conectam com você."
            },
            "fairness": {
                en: "The degree to which you are motivated to ensure that you and others are treated fairly and given an equal opportunity. The higher the Fairness aspect is in you, the more it tracks and evaluates others' behaviors according to social norms.",
                es: "El grado en el que estás motivado para asegurarte de que tu y los demás sean tratados de manera justa y se les brinde las mismas oportunidades. Cuanto más alto es el aspecto Justicia en ti, más rastreas y evalúas los comportamientos de los demás de acuerdo con las normas sociales.",
                pt: "O grau em que você está motivado para garantir que você e os outros sejam tratados de forma justa e tenham oportunidades iguais. Quanto maior o aspecto Equidade em você, mais ele o motiva a avaliar e acompanhar o comportamento dos outros de acordo com as normas sociais."
            },
            "retaliate": {
                en: "The degree to which you are motivated to forcefully react when it is perceived that others are taking advantage of you or someone whom we value. The higher the Retaliate aspect is in you, the more it motivates the tracking of violations of your social expectations.",
                es: "El grado en el que te sientes motivado para reaccionar con fuerza cuando percibe que otros se están aprovechando de ti o de alguien a quien valoramos. Cuanto más alto sea el aspecto Resarcir en ti, más motivará el seguimiento de las violaciones de tus expectativas sociales.",
                pt: "O grau em que você está motivado a reagir com força quando percebe que os outros estão se aproveitando de você ou de alguém que valorizamos. Quanto maior o aspecto Retaliar em você, mais ele motiva o monitoramento de violações de suas expectativas sociais."
            },
            "dominate": {
                en: "The degree to which you are motivated to judge others and to act strongly and in a controlling manner. The higher the Dominate aspect is in you, the more it motivates controlling behaviors towards those perceived as being of a lower status.",
                es: "El grado en el que estás motivado para juzgar a los demás y actuar con firmeza y control. Cuanto más alto es el aspecto de Dominio en ti, más motiva seguir los comportamientos de control sobre aquellos que se perciben como de un nivel inferior.",
                pt: "O grau em que você está motivado a julgar os outros e a agir com força e de maneira controladora. Quanto mais alto o aspecto Dominar estiver em você, mais ele motiva comportamentos controladores em relação àqueles percebidos como de status inferior."
            },
            "status": {
                en: "The degree to which you are motivated to seek recognition and the respect and admiration of others. The higher the Status aspect is in you, the more it motivates the tracking of levels of status in those you engage and then the amount of deference to be exhibited.",
                es: "El grado en el que estás motivado para buscar el reconocimiento y el respeto y la admiración de los demás. Cuanto más alto sea el aspecto Estatus en ti, más te motivará dar seguimiento a los niveles de estatus de aquellos con quienes te relacionas y la deferencia que se debe mostrarles",
                pt: "O grau em que você está motivado a buscar o reconhecimento, o respeito e a admiração dos outros. Quanto mais alto o aspecto Status estiver em você, mais ele motiva o monitoramento dos níveis de status naqueles com quem você se envolve e, em seguida, a quantidade de consideração oferecida."
            },
            "become": {
                en: "The degree to which you are motivated to grow as individuals, master skills, and reach your potential. The higher the Become aspect is in you, the more it motivates the tracking of performance and its evolution leading to the motivation of commensurate effort.",
                es: "El grado en el que estás motivado para crecer como persona, dominar habilidades y alcanzar tu potencial. Cuanto más alto es el aspecto 'Convertirse en' en ti, más motivado estarás para dar seguimiento al desempeño y su evolución, lo que lleva a la motivación acorde al esfuerzo.",
                pt: "O grau em que você está motivado para crescer como indivíduo, dominar habilidades e alcançar seu potencial. Quanto mais alto o aspecto Desenvolvimento em você, mais ele o motiva a acompanhar o seu desempenho e sua evolução levando à motivação para se empenhar mais."
            }
        },
        // FEELING
        feeling: {},
        // FEELINGDESC
        feelingDesc: {},

    }

    if (dictionary?.[type]?.[label]?.[lang]) {
        return dictionary[type][label][lang]
    } else {
        console.log("MISSING TRANSLATION:", type, label, lang)
        return "% " + type + "-" + label + "-" + lang + " %"
    }
}