import React from "react";

export default function AspectScale(props) {
    const width = props.width
    const height = props.height
    const item = props.item

    let scaleArr = []

    for (let i = 0; i < 6; i++) {
        const count = Math.ceil(item.count)
        if (count <= i ) {
            scaleArr.push("#EEE") // white out
        } else {
            scaleArr.push(item.bgcolor)
        }
    }

  return (
    <svg
        width={ width }
        height={ height }
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="1.5"
        clipRule="evenodd"
        viewBox="0 0 138 39"
    >
        <path
            fill="none"
            d="M1492.71 347.185c0-6.7-4.04-12.139-9.01-12.139h-193.01c-4.97 0-9.01 5.439-9.01 12.139v55.903c0 6.699 4.04 12.139 9.01 12.139h193.01c4.97 0 9.01-5.44 9.01-12.139v-55.903z"
            transform="translate(-1310.56 -349.504) translate(-.149 -.778) matrix(.65392 0 0 .48563 472.595 187.574)"
        ></path>
        <path d="M1439.07,349.518L1439.13,380.868L1419.29,380.868L1419.23,353.06L1439.07,349.518Z" 
            fill={ scaleArr[5] }
            stroke="#fff"
            strokeWidth="2"
            transform="translate(-1310.56 -349.504) translate(-.149 -.778) translate(.117 4.558)"
        ></path>
            
        <path d="M1419.24,353.06L1419.3,380.868L1399.45,380.844L1399.39,356.578L1419.24,353.06Z"
            fill={ scaleArr[4] }
            stroke="#fff"
            strokeWidth="2"
            transform="translate(-1310.56 -349.504) translate(-.149 -.778) translate(.117 4.558)"
        ></path>  
            
        <path d="M1399.4,356.578L1399.45,380.868L1379.61,380.868L1379.56,360.077L1399.4,356.578Z"
            fill={ scaleArr[3] }
            stroke="#fff"
            strokeWidth="2"
            transform="translate(-1310.56 -349.504) translate(-.149 -.778) translate(.117 4.558)"
        ></path>  
            
        <path d="M1379.56,360.077L1379.61,380.868L1359.76,380.868L1359.72,363.632L1379.56,360.077Z"
            fill={ scaleArr[2] }
            stroke="#fff"
            strokeWidth="2"
            transform="translate(-1310.56 -349.504) translate(-.149 -.778) translate(.117 4.558)"
        ></path>
            
        <path d="M1359.73,363.632L1359.77,380.868L1339.92,380.868L1339.89,367.137L1359.73,363.632Z"
            fill={ scaleArr[1] }
            stroke="#fff"
            strokeWidth="2"
            transform="translate(-1310.56 -349.504) translate(-.149 -.778) translate(.117 4.558)"
        ></path>  

        <path d="M1339.89,367.137L1339.92,380.868L1320.08,380.868L1320.05,370.699L1339.89,367.137Z"
            fill={ scaleArr[0] }
            stroke="#fff"
            strokeWidth="2"
            transform="translate(-1310.56 -349.504) translate(-.149 -.778) translate(.117 4.558)"
        ></path>
    </svg>
  );
}