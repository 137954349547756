import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Collapse, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import { Profile, Menu, Team, Header, Translate as t, Needs } from '../Components';
import { GetTestProfile, setRouteVars, validateRoute } from '../Functions';
import theme from '../Theme';
// import { useSwipeable } from 'react-swipeable'; // TO BE UNINSTALLED IF NOT USED

// TODO LIST
// 1) Add radar image to email using the code to call the image ?code= X
// 2) Clear storage on this device (popup with option to copy profile code if any)
// 3) ADD ABILITY TO REDO THE PROFILE
// 4) FIX RETAKE OF THE PROFILE NOT MEASURING TIME TAKEN
// 5) CREATE NEW TEAM VIEWS

export default function App() {
    const versionNum = 'beta-v0.4.8'
    const [ session, setSession ] = useState({
        logonTime: moment(), language: 'en', startTime: '', endTime: '', duration: '', 
        version: versionNum, name: '', email: '', referrer: '', 
        org: '', team: '', profileCode: '', reportRun:'' })
    const [ l, setL ] = useState(null)
    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ profileStage, setProfileStage ] = useState('Setup')
    const [ results, setResults ] = useState([])
    const [ profile, setProfile ] = useState(0)
    const [ testMode, setTestMode ] = useState(false)
    const [ route, setRoute ] = useState('/')
    const [ subroute, setSubroute ] = useState('')
    const [ team, setTeam ] = useState([])
    const [ setupDone, setSetupDone ] = useState(false)
    const [ profileDone, setProfileDone ] = useState(false)
    const [ teamListDone, setTeamListDone ] = useState(false)
    const [ menuOpen, setMenuOpen ] = useState(true)

    let MedallionImage = ''
    if (l !== null) MedallionImage = require('../Assets/RadicalPurpose-Medallion-' + l.toUpperCase() + '-300.jpg');
    
    const langs = [ 'en', 'es', 'pt' ]
    const routeMatrix = [ 
        { root: "profile", subroutes: [] }, 
        { root: "needs", subroutes: [] }, 
        { root: "feelings", subroutes: [] }, 
        { root: "team", 
            subroutes: [ 
                { root: "report" } 
            ]
        } 
    ]
    const defaultLang = 'en'
    const pathString = useLocation().pathname
    const path = getPathElements(pathString)
    const navigate = useNavigate()

    // SET THE ROUTE VARIABLES
    setRouteVars(langs, routeMatrix, t)

    const isValidLang = (lang => langs.includes(lang))

    // SET URL TO DEFAULT LANGUAGE
    useEffect(() => {
        if (path.lang === '') updateURL({ lang: defaultLang })
    })

    // SET APP LANGUAGE & ROUTE FROM URL STATE
    useEffect(() => {
        if ( path.lang !== '') {
            if (path.lang !== l) {
                if (isValidLang(path.lang)) setL(path.lang)
            }
        }
        if ( path.route !== '' ) {
            const newRoute = validateRoute( path )
            if (route !== newRoute.route) setRoute(newRoute.route)
            if (subroute !== newRoute.subroute) setSubroute(newRoute.subroute)
        } else {
            if ( route !== '') setRoute('') 
            if ( subroute !== '') setSubroute('') 
        }
    },[ isValidLang, path, route, subroute, l ])

    // GET LOCALSTORAGE DATA 
    useEffect(() => {    
        if (session.name === "" && session.email === "" && l !== null) {
            const storedSession = JSON.parse(localStorage.getItem('session'))
            if (storedSession) {   
                session.duration = storedSession.duration
                session.email = storedSession.email
                session.endtime = storedSession.endtime
                session.name = storedSession.name
                session.org = storedSession.org
                session.referrer = storedSession.referrer
                session.name = storedSession.name
                session.startTime = storedSession.startTime
                session.version = storedSession.version
                session.reportRun = storedSession.reportRun
                session.profileCode = storedSession?.profileCode
                updateSession(session)
                setSetupDone(true)
                if (session.profileCode.length === 13) {
                    setProfileStage("Report")
                    //updateURL({ lang: l, route: "profile", subroute: "" })
                }
            }
        }
    
        if (results.length === 0) {
            // console.log("CHECKING RESULTS STORAGE")
            const storedResults = JSON.parse(localStorage.getItem('results'))
            // console.log(storedResults)
            if (storedResults) {
                if (storedResults.length !== 0) {
                    updateResults(storedResults)
                    setProfileDone(true)
                }
            }
        }

        if (team.length === 0) {
            // console.log("CHECKING TEAM STORAGE")
            const storedTeam = JSON.parse(localStorage.getItem('team'))
            // console.log(storedTeam)
            if (storedTeam) {
                if (storedTeam.length !== 0) {
                    updateTeam(storedTeam)
                    setTeamListDone(true)
                }
            }
        }

    }, [ session, session.name, session.email, results, team, l ])

    const testProf = GetTestProfile()

    // console.log("Test Profile:", testProf)

    function changeLanguage(lang) {
        updateURL({ lang: lang })
    }

    function getPathElements(tempPath){
        const pathObj = { lang: defaultLang, route: '', subroute: ''}
        if (typeof tempPath === 'string') {
            const pathArray = tempPath.substring(1).split('/')
            pathObj.lang = pathArray[0] ? pathArray[0] : ""
            pathObj.route = pathArray[1] ? decodeURIComponent(pathArray[1]) : ""
            pathObj.subroute = pathArray[2] ? decodeURIComponent(pathArray[2]) : ""  
        }
        return pathObj
    }

    function updateURL(routeObj){
        const temp = {}
        temp.lang = (routeObj?.lang !== undefined) ? routeObj.lang : l
        temp.route = (routeObj?.route !== undefined) ? routeObj.route : route
        temp.subroute = (routeObj?.subroute !== undefined) ? routeObj.subroute : subroute

        if ( temp.route === '' ) {
            temp.route = "INVALID"
            temp.subroute = "INVALID"
        } else if ( temp.subroute === '' ) {
            temp.subroute = "INVALID"
        }

        let fullRoute = "/" + temp.lang 
        if (temp.route !== "INVALID") {
            if (temp.route === '/') {
                fullRoute = fullRoute + "/"
            } else {
                fullRoute = fullRoute + "/" + temp.route
            }
            if (temp.subroute !== "INVALID") {
                fullRoute = fullRoute  + "/" + temp.subroute
            }
        } else {
            fullRoute = fullRoute + "/"
        }
        navigate(fullRoute)
        if (routeObj?.click) updateMenuOpen()
    }

    function updateMenuOpen(action){
        if (action) {
            if (action === 'open') setMenuOpen(true)
            if (action === 'close') setMenuOpen(false)
        } else {
            setMenuOpen(!menuOpen)
        }
    }

    function updateSession(newSession){
        setSession(newSession)
        setName(session.name)
        setEmail(session.email)
        if (newSession.name !== '' && newSession.email !== '') {
            localStorage.setItem('session', JSON.stringify(newSession))
        }
    }

    function updateProfileStage(newStage){
        setProfileStage(newStage)
    }

    function updateResults(newResults){
        setResults(newResults)
        localStorage.setItem('results', JSON.stringify(newResults))
    }

    function updateProfile(newProfile) {
        setProfile(newProfile)
    }

    function updateTeam(newTeam) {
        setTeam(newTeam)

        // STRIP OUT THE LARGE PROFILE OBJECT
        // const storableTeam = Object.assign([], newTeam)
        // newTeam.forEach((e, i) => {
        //     delete storableTeam[i].profile
        // })
        localStorage.setItem('team', JSON.stringify(newTeam))
    }

    function updateTestMode(value) {
        setTestMode(value)
    }

    const headerProps = { route, subroute, updateURL, updateMenuOpen, t, l }

    const menuProps ={ route, updateURL, changeLanguage, updateMenuOpen, t, l, path }

    const profileProps = { 
        versionNum, t, l, name, email, session, updateSession, profileStage, updateProfileStage, results, 
        updateResults, profile, updateProfile, testMode, updateTestMode, testProf, setupDone, profileDone, 
    }

    const teamProps = { route, subroute, updateURL, t, l, team, updateTeam, teamListDone, session }


    const needsProps = { route, subroute, updateURL, t, l }

    // console.log("CURRENT ROUTE:", "|"+route+"|", subroute)

    // console.log('menuOpen', menuOpen)

    const licenseURL = "https://creativecommons.org/licenses/by-sa/4.0/deed." + l 

    // STUFF TO DO WITH REACT-SWIPEABLE
    // type Direction = typeof PREV | typeof NEXT;

    // const handlers = useSwipeable({
    //     onSwipedLeft: () => slide("LEFT"),
    //     onSwipedRight: () => slide("RIGHT"),
    //     preventDefaultTouchmoveEvent: true,
    //     trackMouse: true
    //   });

    // const slide = (dir: Direction) => {
    // dispatch({ type: dir, numItems });
    // setTimeout(() => {
    //     dispatch({ type: 'stopSliding' });
    // }, 50);
    // };

    // console.log("SUBROUTE |", subroute, "|")

    if (l === null) return null

    return (
        <ThemeProvider theme={theme}>
            <Box width='100%' justifyContent="center">
                <Box maxWidth="1024px" marginLeft="auto" marginRight="auto">
                    <Header { ...headerProps } />
                    <Collapse in={menuOpen}>
                        <Menu { ...menuProps } />
                    </Collapse>

                    { ( route === "") &&
                        // <Profile />
                        <Box display="flex" mt={ 2 } mb={ 5 } justifyContent="center" flexDirection="column">
                            {/* <Box>
                                <Typography variant="body2" align="center"><strong>{ t("title", "select-language", l) }</strong></Typography> */}
                                {/* Please select your language: */}
                                {/* <Typography variant="body2" align="center">
                                    <input type="radio" value="en" name="language" defaultChecked={ (l  === 'en') } onChange={() =>  changeLanguage("en") } /> English &nbsp;
                                    <input type="radio" value="es" name="language" defaultChecked={ (l  === 'es') } onChange={() => changeLanguage("es") } /> Español &nbsp; */}
                                    {/* <input type="radio" value="pt" name="language" defaultChecked={ (l  === 'pt') }onChange={() => changeLanguage("pt") } /> Português */}
                                {/* </Typography>
                            </Box> */}
                            <Box display="flex" mt={ 5 } justifyContent="center">
                                <img src={ MedallionImage } alt="Radical Purpose Needs" width="300" height="300" />
                            </Box>
                        </Box>
                    }

                    { ( route === t("route", "profile", l) ) &&
                        <Profile { ...profileProps } />
                    }

                    { ( route === t("route", "needs", l) ) &&
                        <Needs {...needsProps} />
                    }

                    {/* { ( route === 'feelings') &&
                        <Feelings {...feelingsProps} />
                    } */}

                    { ( route === t("route", "team", l) )  &&
                        <Team { ...teamProps } />
                    }
                    
                    <Box display="flex" flexDirection="column" width='100%' mt={ 1 } mb={ 2 } alignItems="center" justifyContent="center">
                        <Typography variant="caption" align="center" paragraph>&copy; 2020-{ new Date().getFullYear() } Radical ({ versionNum })<br/>{ t( "title", "cc-license", l) } <a href={ licenseURL } target="_blank" rel="noopener noreferrer">CC-BY-SA 4.0</a> </Typography>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
}