import React from 'react';
import { Box, Chip, Divider, Typography } from '@material-ui/core';
import ProfileButton from '../Assets/ProfileButton.png';
import TeamButton from '../Assets/TeamButton.png';
import NeedsButton from '../Assets/NeedsButton.png';
import FeelingsButton from '../Assets/FeelingsButton-disabled.png';
import { getLangRoute } from '../Functions/Route';




export default function Menu(props) {
    const { t, l, route, updateURL, path } = props
    const size = "78px"

    // GET MATCHING ROUTE & SUBROUTE FOR NEW LANG
    function changeLanguage ( newLang ){
        const newRoute = getLangRoute(newLang, path)
        updateURL(newRoute)
    }

    // return NULL if translation is not available
    if ( t("button", "profile", l) === undefined ) return null

    return (
        <Box display="flex" alignItems="center" flexDirection="column">
            <Box display="flex" mt={ .5 } ml={ 1 } mr={ 1 } mb={ 2.5 } alignItems="center" 
                justifyContent="center" flexDirection="row" flexWrap="wrap">
                {/* <Box m={ .5 } className="App-link" display="flex" flexDirection="column" alignItems="center"
                    onClick={ () => updateURL( { route: t("route", "profile", l), click: true } )  }> */}
                <Box className="App-link" width={ size } height={ size } p='0' m='0' border="solid 4px" borderRadius="50%"
                    borderColor={ (route === t("button", "profile", l).toLowerCase()) ? '#00ADEF' : 'white' }
                    onClick={ () => updateURL( { route: t("route", "profile", l), subroute: "", click: true } ) }>

                    {/* <Tooltip title={ t("button", "profile", l) } arrow > */}
                        <img src={ ProfileButton } alt="Radical Purpose Profile" width={ size } height={ size } />
                    {/* </Tooltip> */}
                    <Box>
                        <Typography align="center" variant="subtitle2" style={{ fontSize: "12px" }}
                        color={ (route === t("button", "profile", l).toLowerCase()) ? 'primary' : 'textSecondary' }>
                            { t("button", "profile", l) }
                        </Typography>
                    </Box>
                </Box>
                <Box className="App-link" width={ size } height={ size } p='0' m='0' border="solid 4px" borderRadius="50%"
                    borderColor={ (route === t("button", "needs", l).toLowerCase()) ? '#00ADEF' : 'white' }
                    onClick={ () => updateURL( { route: t("route", "needs", l), subroute: "", click: true } ) }>
                    {/* <Tooltip title={ t("button", "needs", l) } arrow > */}
                        <Box><img src={ NeedsButton } alt="Radical Purpose Needs" width={ size } height={ size } /></Box>
                    {/* </Tooltip> */}
                    <Box>
                        <Typography align="center" variant="subtitle2" style={{ fontSize: "12px" }}
                            color={ (route === t("button", "needs", l).toLowerCase()) ? 'primary' : 'textSecondary' }>
                            { t("button", "needs", l) }
                        </Typography>
                    </Box>
                </Box>
                <Box 
                    // className="App-link" 
                    width={ size } height={ size } p='0' m='0' border="solid 4px" borderRadius="50%"
                    borderColor={ (route === t("button", "feelings", l).toLowerCase()) ? '#00ADEF' : 'white' } >
                    {/* onClick={ () => updateURL( { route: t("route", "feelings", l), subroute: "", click: true } ) }> */}

                    {/* <Tooltip title={ t("button", "feelings", l) } arrow> */}
                        <Box><img src={ FeelingsButton } alt="Radical Purpose Feelings" width={ size } height={ size } /></Box>
                    {/* </Tooltip> */}
                    <Box>
                        <Typography align="center" variant="subtitle2" color="textSecondary" style={{ color: '#c1c1c1', fontSize: "12px" }} >
                            { t("button", "feelings", l) }
                        </Typography>
                    </Box>
                </Box>
                <Box className="App-link" width={ size } height={ size } p='0' m='0' border="solid 4px" borderRadius="50%"
                    borderColor={ (route === t("button", "team", l).toLowerCase()) ? '#00ADEF' : 'white' }
                    onClick={ () => updateURL( { route: t("route", "team", l), subroute: "", click: true } ) }>

                    {/* <Tooltip title={ t("button", "team", l) } arrow> */}
                        <Box><img src={ TeamButton } alt="Radical Purpose Profile Team" width={ size } height={ size } /></Box>
                    {/* </Tooltip> */}
                    <Box>
                        <Typography align="center" variant="subtitle2" style={{ fontSize: "12px" }}
                            color={ (route === t("button", "team", l).toLowerCase()) ? 'primary' : 'textSecondary' }>
                            { t("button", "team", l) }
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box mt={ 1 } width='300px'>
                <Divider variant="middle" />
            </Box>
            <Box display="flex" mt={ 2 } ml={ 1 } mr={ 1 } mb={ 2 } alignItems="center" justifyContent="center" flexDirection="row" flexWrap="wrap">
                <Chip label="English" size="small" color={ (l === 'en') ? 'primary' : 'default' } style={{ margin: '2px'}}
                    clickable onClick={ () => changeLanguage("en") } />
                <Chip label="Español" size="small" color={ (l === 'es') ? 'primary' : 'default' } style={{ margin: '2px'}}
                    clickable onClick={ () => changeLanguage("es") }/>
                <Chip label="Português" size="small" color={ (l === 'pt') ? 'primary' : 'default' } style={{ margin: '2px'}} 
                    clickable onClick={ () => changeLanguage("pt") } />
                    {/* disabled={ true } */}
            </Box>
        </Box>
    )
}