import React, { Fragment } from 'react';
import { Box, Typography, Fab } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Card } from '../Core';

export default function Team(props) {
    function removeMember(id){
        const newTeam =  props.team.filter(function(member) {
            return member.id !== id;
        });
        props.updateTeam(newTeam)
          
    }

    return (
        <Fragment key={ props.newMemberFlag }>
        { props.team.map((member) => (
            <Box p={ 1 } display="flex" justifyContent="left" alignItems="center" key={ member.id }>
                <Card height='100%' width='250px'>
                    <Box display="flex" justifyContent="left" alignItems="center" key={ member.id }>
                        <Box height="50px" pl={ 2 } display="flex" justifyContent="left" alignItems="center" > 
                            <strong>
                                <Typography variant="subtitle2" component="h2" style={{ lineHeight: "50px" }}>{ member.name.toUpperCase() }:</Typography>
                            </strong>
                        </Box>
                        <Box height="50px" pl={ 1.5 } display="flex" justifyContent="left" alignItems="center" > 
                            <Typography variant="overline" component="h2" style={{ lineHeight: "50px" }}>{ member.profileCode.toUpperCase() }</Typography>
                        </Box>
                    </Box>
                </Card>
                <Box>
                    <Fab size="small" style={{ backgroundColor: "white" }} onClick={ () => removeMember(member.id) } >
                        <Delete style={{ color: "#d3d3d3" }}/>
                    </Fab>
                </Box>
            </Box>
        ))}
    </Fragment>
    );
}