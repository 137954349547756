import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';

export default function Excercise(props) {
    const { t, l } = props

    return (
        <Box width="100%" display="flex" maxWidth='800px' flexDirection="column" alignItems="center" 
            justifyContent="center" className="pagebreak">
            <Box width="90%" ml={ 1 } mb={ 1 } mt={ 2 } color="#fff" bgcolor="#000" p={ 1 } borderRadius="6px" alignItems="center" justifyContent="center" fontWeight="fontWeightMedium">
                <Typography variant="h5" color="inherit" align="center">{ t('title', 'exercise', l) }</Typography>
            </Box>
            <Box width="90%" mb={ 1 } mt={ 2 } p={ 1 } borderRadius="6px" alignItems="center" justifyContent="center" fontWeight="fontWeightMedium">
                <Typography variant="body2" align='left' paragraph>{ t('paragraph', "all-of-the-radical-needs-are-present-in-us", l) }</Typography>
                <Typography variant="body2" align='left' paragraph><strong>{ t('paragraph', "below-is-an-exercise-to-help-you-interpret", l) }</strong></Typography>
                <Typography variant="body2" align='left' paragraph><strong>{ t('paragraph', "pick-one-of-your-high-radical-needs", l) }</strong></Typography>
                <ul>
                    <li><Typography variant="body2" align='left' paragraph>{ t('paragraph', "what-behaviors-and-actions-in-your-daily-life", l) }</Typography></li>
                    <li><Typography variant="body2" align='left' paragraph>{ t('paragraph', "how-do-you-feel-when-the-need-is-met", l) }</Typography></li>
                    <li><Typography variant="body2" align='left' paragraph>{ t('paragraph', "how-do-you-feel-when-the-need-is-not-met", l) }</Typography></li>
                </ul>
            </Box>
        </Box>
    )
}