import React, { useEffect, useState } from 'react';

export default function getProfileCode( profile ) {
    const aspCounts = profile.aspCounts
    const theNeed = {
        11: "1", 12: "2", 13: "3", 14: "4", 15: "5", 16: "6", 
        21: "7", 22: "8", 23: "9", 24: "A", 25: "B", 26: "C", 
        31: "D", 32: "E", 33: "F", 34: "G", 35: "H", 36: "I",
        41: "J", 42: "K", 43: "L", 44: "M", 45: "N", 46: "O",
        51: "P", 52: "Q", 53: "R", 54: "S", 55: "T", 56: "U",
        61: "V", 62: "W", 63: "X", 64: "Y", 65: "Z", 66: "-"
    } 
    let code = ""
    let numericCode = ""

// console.log("ASP COUNTS", aspCounts)
        
    aspCounts.forEach((asp) => {
        numericCode = numericCode + Math.ceil(asp.count)
    })

    const numCodeArr = numericCode.split("")

// console.log("CODE ARRAY", numCodeArr)

    for (let i=0; i < 25; i=i+2){
        let nums = ""
        const numA = (numCodeArr[i].toString() > 6) ? 6 : numCodeArr[i].toString()
        if (i < 24) {
            const numB = (numCodeArr[i+1].toString() > 6) ? 6 : numCodeArr[i+1].toString()
            nums = numA + numB
        } else {
            nums = numA + "1"
        }
// console.log("CODE NUM", nums)
        code = code + theNeed[nums]

// console.log("CODE", code)
    }

    return code
}