import React, { useState, useEffect } from 'react';
import { Box, Card, Typography, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import RadicalLogoMagenta from '../Assets/RadicalLogoMagenta';

export default function Header(props) {
    const { t, l, updateURL, updateMenuOpen, route, subroute } = props
    const [ title, setTitle ] = useState("")

    useEffect(() => {
        if (route === "/" || route === "") {
            setTitle(t('title', 'radical-purpose', l))
        } else {
            setTitle( route.toUpperCase() + " " + subroute.toUpperCase())
        }
    }, [ route, subroute, l, t ])

    function goHome(){
        updateURL({ lang: l, route: '', subroute: '' })
        updateMenuOpen('open')
    }

    return (
        <Card>
            <Box width='100%' height="46px" display='flex' justifyContent='space-between'>
                <Box ml={ .5 } mt={ .5 } mb={ .5 } alignSelf="flex-center" className="App-link" onClick={ () => goHome() }>
                    <RadicalLogoMagenta width='38px' height='38px'/>
                </Box>
                <Box alignSelf="center">
                    <Typography variant="h6">{ title }</Typography>
                </Box>
                <Box width="44px" display="flex" alignSelf="center">
                    <IconButton aria-label="menu" onClick={ () => updateMenuOpen() }>
                        <MenuIcon size="medium" />  
                    </IconButton>
                </Box>
            </Box>
        </Card>
    )
}
