import { useEffect } from 'react';
import emailjs, { init } from 'emailjs-com';
import getProfileCode from './getProfileCode';

init("user_tpNsozVVoXtISJn3wyQri")

export default function SendEmail(props){
    // const data = require('../Assets/RpData.json');
    // const aspList = data.asps;
    // const dimList = data.dims;

    const { t, l, session, profile, versionNum } = props
    const dimCounts = profile.dimCounts
    const aspCounts = profile.aspCounts


// console.log(profile)


    let templateParams = {
        to_name: session.name,
        to_email: session.email,
        message: ''
    }

    useEffect(() => { 
        if (templateParams.message === '') {
            const tempMessage = generateEmail()
            templateParams.message = tempMessage
            sendIt()

            // templateParams.to_name = "Jose"
            // templateParams.to_email = "jose@radicalpurpose.org"
            // sendIt()
        }
    },[])

    function generateEmail(){
        const profileCode = getProfileCode(profile)

console.log("EMAIL CODE", profileCode)

        const header = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml" lang="en-US"><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8" /><title>' + t('title', 'radical-purpose-profile', l) + '</title><meta name="viewport" content="width=device-width, initial-scale=1.0"/></head>'
        const bodyTop = '<body style="margin: 0; padding: 0;"><div width="640px"><table role="presentation" border="0" cellpadding="0" cellspacing="0" width="640px">'
        const bodyEmailTop = '<tr><td style="margin: 0; text-align: left; vertical-align: bottom; padding: 0;"><p style="font-size: small;">' + t('paragraph', 'hello', l) + ' ' + session.name + ',<br>&nbsp;</br><br>' + t('paragraph', 'thank-you-for-completing', l) + ' ' + t('paragraph', 'we-are-still-in-beta', l) + '</br><br>&nbsp;</br><br>' + t('paragraph', 'below-is-the-results', l) + '</br></p></td></tr>'
        const bodyDomHeader = '<tr><td style="background-color: #000; color: #fff; text-align: center; vertical-align: middle;"><b><h2>' + t('title', 'radical-purpose-profile', l) + ' (' + session.name + ')</h2></b></td></tr><tr><td>&nbsp;</td></tr><tr><td style="margin: 0; text-align: center; vertical-align: bottom; padding: 0;"><img src="https://profile.radicalpurpose.org/static/media/RadicalPurpose-Medallion-EN-300.2bb7e5826f30ee5ef668.jpg" /></td></tr><tr><td style="text-align: center; vertical-align: middle;"><h2>' + t('title', 'your-profile-code', l) + ': ' + profileCode + '</h2></td></tr><tr><td style="height: 12px; background-color: #000; color: #fff; text-align: center; vertical-align: middle;"><b><h3>' + t('title', 'your-radical-needs', l) + '</h3></b></p></td></tr>'
        const bodyDomDims = calcDoms()
        const bodyBottom = '<tr><td style="background-color: #000; color: #fff; text-align: center; vertical-align: middle;"><p style="font-size: x-small;">&copy; 2020 Radical Movement (' + versionNum + ')</p></td></tr>'
        const bodyEmailBottom = '<tr><td style="margin: 0; text-align: left; vertical-align: bottom; padding: 0;"><p style="font-size: small;"><br>' + t('paragraph', 'best-wishes', l) + '</br><br>' + t('paragraph', 'radical-purpose-team', l) + '</br></p></td></tr></table></div></body></html>'
        const content = header + bodyTop + bodyEmailTop + bodyDomHeader + bodyDomDims + bodyBottom + bodyEmailBottom
        return content
    }

    function calcDoms(){
        let html = ''
        for (let i = 0; i < 5; i++) {
                const dimName = t('radicalNeed', dimCounts[i].name, l)
                const description = t('radicalNeedDesc', dimCounts[i].name, l)
                const needLabel = t('title', dimCounts[i].name + "-needs", l)
                // let iconUrl = ""
                // if (dimCounts[i].name === "being") {
                //     iconUrl = "https://profile.radicalpurpose.org/static/media/BeingIcon.a237dae671dbd43966f5.png"
                // } else if (dimCounts[i].name === "meaning") {
                //     iconUrl = "https://profile.radicalpurpose.org/static/media/MeaningIcon.b091e3b02e0617a30b98.png"
                // } else if (dimCounts[i].name === "impacting") {
                //     iconUrl =
                // } else if (dimCounts[i].name === "belonging") {
                //     iconUrl =
                // } else if (dimCounts[i].name === "becoming") {
                //     iconUrl = ""
                // }

                html = html + '<tr><td style="width:640px; height: 12px; text-align: center; vertical-align: bottom; margin: 0; padding: 0;"><h3><p style="margin: 0;"><b>' + dimName.toUpperCase() + '</b></p></h3></td></tr>';
                html = html + '<tr><td style="width:640px; text-align: left; vertical-align: middle; margin: 0; padding: 0;"><p style="margin: 0;">' + description + '</p></td></tr>';
                html = html + '<tr><td style="width:640px; text-align: left; vertical-align: bottom; margin: 0; padding: 0;"><p><h4>' + needLabel.toUpperCase() + '</h4></p></td></tr>'
                html = html + calcAspects(dimCounts[i].id)
        }
        return html
    }

    function calcAspects(dimId){
        let html = ''
        for (let i = 0; i < 25; i++) {
            if (aspCounts[i].dimId === dimId) {
                const aspName = t('need', aspCounts[i].aspName, l)
                const aspRank = t('title', aspCounts[i].rank, l)
                const aspDesc = t('needDesc', aspCounts[i].aspName, l)
                
                html = html + '<tr style="background-color: #ddd;"><td style="margin: 0; text-align: left; vertical-align: bottom; padding: 5px;"><p style="font-size: medium; line-height: 75%;"><b>' + aspName + ' [' + aspRank + ']</b></p>'
                html = html + '<p style="font-size: small;">' + aspDesc + '</p></td></tr>'
            }
        }
        return html
    }

    function sendIt(){
        emailjs.send('service_1gfath8','template_ilkph64', templateParams)
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
        }, function(err) {
           console.log('FAILED...', err);
        });
    }

    return null
}