import React, { Fragment, useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, TextField, Typography } from '@material-ui/core';

export default function CodeDialog(props) {
  const { closeDialog, open } = props;

  return (
    <Dialog onClose={ closeDialog } open={ open }>
      <DialogTitle>Add Existing Profile Code</DialogTitle>
        <Box width="300px">
            <Box>
                THIS IS CONTENT TO SHOW
                {/* <TextField id="profileCode" value={ profileCode } label={ t('fieldLabel', 'profile-code', l) }  */}
                    {/* variant="outlined" onChange={ handleCodeChange } inputProps={{ maxLength: 13 }} /> */}
            </Box>
        
        </Box>

    </Dialog>
  );
}

