const fistSelectionValue = 1.25 // value of first select giving higher weight
const domThreshold = 11 // (11) mid point or adjusted value for dominant dimensions
const lowThreshold = 6 // Threshold for LOW DIMENSIONS
const highThreshold = 13 // Threshold for HIGH DIMESIONS
const aspLowRank = 2
const aspMedRank = 4

export default function calcProfile(results, source) {
    const data = require('../Assets/RpData.json')
    const aspList = data.asps;
    const dimList = data.dims;

    let tmpProf = {}
    if (source === "profilecode") {
        tmpProf.dimCounts = calcDimsProfileCode(dimList, results)
    } else {
        tmpProf.dimCounts = calcDims(dimList, results)
    }

    tmpProf.aspCounts = calcAspects(aspList, dimList, results, source)
    return tmpProf
}

function calcDims(dimList, results){
    const dimCounts = dimList
    for (let i = 0; i < 5; i++) {
        dimCounts[i].count = 0
    }
    for (let i = 0; i < 5; i++) {
        results.map(item => {
            dimCounts[ i ].count = dimCounts[ i ].count + dimCount( i + 1 , item)
            dimCounts[ i ].dominant = dimCounts[ i ].count >= domThreshold ? true : false
            dimCounts[ i ].level = dimCounts[ i ].count >= highThreshold ? "High" : "Med"
            if (dimCounts[ i ].level === "Med") {
                dimCounts[ i ].level = dimCounts[ i ].count <=  lowThreshold ? "Low" : "Med"
            }
            return 0
        })
    }
    return dimCounts
}

function calcDimsProfileCode(dimList, results){


    console.log("CALC CODE")

    const dimCounts = dimList
    for (let i = 0; i < 5; i++) {
        dimCounts[i].count = 0
    }
    // for (let i = 0; i < 5; i++) {
        results.map((item, index) => {
            const i = Math.floor(index / 5)
            dimCounts[ i ].count = dimCounts[ i ].count + item
            const top = (i + 1) * 5
            if ((index + 1) === top) {
                dimCounts[ i ].dominant = dimCounts[ i ].count >= (domThreshold + 5.5) ? true : false
                dimCounts[ i ].level = dimCounts[ i ].count > 18 ? "High" : "Med"
                if (dimCounts[ i ].level === "Med") {
                    dimCounts[ i ].level = dimCounts[ i ].count < 11 ? "Low" : "Med"
                }
            }
            return 0
        })
    // }
    return dimCounts
}

function dimCount(dimId, item){ // assesses if increment amount is one or more
    let itemValue = 0
    if ( item.dimId === dimId ){
        itemValue = ( item.selectOrder === 1 ) ? fistSelectionValue : 1
        // itemValue = 1
        // if ( item.selectOrder === 1 ) itemValue = fistSelectionValue
    }
    return itemValue
}

function calcAspects(aspList, dimList, results, source){
    // const aspects = [...new Set(aspList.map(item => item.aspName))]
    const aspCounts = []
    // assign count and colors keys
    for (let i = 0; i < 25; i++) {
        const dimIndex = aspList[i].dimId - 1 // id is greater than index
        const obj = { 
            id: i + 1,
            aspName: aspList[ i ].aspName,
            dimId: aspList[ i ].dimId,
            dimName: aspList[ i ].dimName,
            bgcolor: dimList[ dimIndex ].bgcolor, 
            color: dimList[ dimIndex ].color, 
            aspDescription: aspList[ i ].aspDescription,
            count: .5, 
            rank: "" 
        }
        if (source === "profilecode") {
            obj.count = results[ i ]
        }

        aspCounts.push(obj)
    };

    // update counts & add rank
    for (let i = 0; i < 25; i++) {
        results.map(item => {
            aspCounts[ i ].count = aspCounts[ i ].count + aspCounter( i + 1 , item) 
            let rank = "Low"
            if (aspCounts[ i ].count > aspLowRank) rank = "Med"
            if (aspCounts[ i ].count > aspMedRank) rank = "High"
            aspCounts[ i ].rank = rank
            return 0
        })
    }
    return aspCounts
}

function aspCounter(aspId, item){
    let value = 0
    if ( item.id === aspId ){
        value = 1
        if ( item.selectOrder === 1 ) value = fistSelectionValue // first selection is weighted heavier
    }
    return value
}