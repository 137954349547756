import React, { useState, useRef, useEffect }  from 'react';
import { Box } from '@material-ui/core';
import RadarEN from './RadarEN';
import RadarES from './RadarES';
import RadarPT from './RadarPT';

export default function EssenceRadar(props) {
    const { l, t, profile, radarDiam } = props
    const dimCounts = profile.dimCounts
    const aspCounts = profile.aspCounts
    const [ bars, setBars ] = useState(null)
    
    // ROUND OUT THE COUNT RESULTS FOR BARS
    let tBars = [
        [], [], [], [], [],     // BEING
        [], [], [], [], [],     // MEANING
        [], [], [], [], [],     // IMPACTING
        [], [], [], [], [],     // BELONGING
        [], [], [], [], [],     // BECOMING
    ]
    aspCounts.forEach((asp, index) => {
        const count = Math.ceil(asp.count)
        for (let i = 0; i < 6; i++) {
            if (count <= i ) {
                tBars[index].push("none")
            } else {
                tBars[index].push(asp.bgcolor)
        }}
    })
    if (!bars) setBars(tBars);

    // GET LEVELS FOR THE FIVE DIMENSIONS
    const levels = {}
    dimCounts.forEach((dim) => {  // Turns to UPPERCASE & TRANSLATES
        levels[ dim.name ] = t('title', dim.level, l).toUpperCase()
    })

    return (
        <Box key={ l } pt={ 1 } width={ props.radarDiam } height={ props.radarDiam }>
            { (l === 'en') &&
                <RadarEN radarDiam={ radarDiam } bars={ bars } levels={ levels } code={ profile.profileCode} />
            }
            { (l === 'es') &&
                <RadarES radarDiam={ radarDiam } bars={ bars } levels={ levels } code={ profile.profileCode}/>
            }
            { (l === 'pt') &&
                <RadarPT radarDiam={ radarDiam } bars={ bars } levels={ levels } code={ profile.profileCode} />
            }
        </Box>
    )
}