import React, { useState, useEffect, Fragment } from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import { Button } from '../Core';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/min/locales.min'

const pattern = [  // aspect pairing pattern
    [1, 6, 11, 16, 21],
    [2, 7, 12, 17, 22],
    [3, 8, 13, 18, 23],
    [4, 9, 14, 19, 24],
    [5, 10, 15, 20, 25],
    [1, 7, 13, 19, 25],
    [2, 8, 15, 17, 24],
    [3, 9, 12, 20, 22],
    [4, 10, 14, 17, 25],
    [5, 6, 15, 19, 23],
    [1, 8, 14, 20, 21],
    [2, 9, 11, 18, 24],
    [3, 10, 13, 20, 23],
    [4, 6, 14, 18, 21],
    [5, 7, 11, 20, 24],
    [1, 9, 15, 16, 22],
    [2, 10, 12, 18, 25],
    [3, 6, 13, 16, 23],
    [4, 7, 15, 18, 22],
    [5, 8, 12, 16, 25],
    [1, 10, 11, 17, 23],
    [2, 6, 12, 19, 21],
    [3, 7, 14, 16, 24],
    [4, 8, 11, 19, 22],
    [5, 9, 13, 17, 21]
]

function initBS(){ // initialize Button State
    return [ false, false, false, false, false, false, false, false, false, false, false, false, false, 
        false, false, false, false, false, false, false, false, false, false, false, false, false ]
}

export default function Survey(props) {
    const t = props.t
    const l = props.l
    const [ page, setPage ] = useState(1)
    const [ currentSet, setCurrentSet ] = useState([])
    const [ lastSet, setLastSet ] = useState([])
    const [ firstSelection, setFirstSelection ] = useState(0)
    const [ buttonState, setButtonState ] = useState(initBS())
    const [ progress, setProgress ] = useState(0)
    const [ delay, setDelay ] = useState(false)
    const [ redo, setRedo ] = useState(false)
    const [ hidePrev, setHidePrev ] = useState(false)
    // const [ pause, setPause ] = useState(false)
    
    let delayInt

    const aspList = require('../Assets/RpData.json').asps;

    useEffect(() => { 
        if (page === 1) changeCurrentSet(1) 
    },[])

    useEffect(() => { 
        if (redo) {
            while (props.results.length > (page - 1)  * 2) { 
                let tempResults = props.results
                tempResults.pop()
                props.updateResults(tempResults)
            }
            changeCurrentSet(page)
            setRedo(false)
            setHidePrev(true)
        }
    })

    function randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function randomizeSet(patternSet) {
        let usedArray = []
        let randSet = []
        while (randSet.length !== 5) {
            let foundIndex = 0
            let randIndex = randomInteger(0,4)
            while (foundIndex !== -1) {
                foundIndex = usedArray.indexOf(randIndex)
                if (foundIndex === -1) {
                    usedArray.push(randIndex)
                    randSet.push(randIndex)
                } else {
                    randIndex = randomInteger(0,4)
                }
            }
        }
        return randSet
    }

    function selectItem(aspId){

console.log("ASPID:", aspId)


        let selectionObj = {
            id: aspId,
            aspPrompt: aspList[ aspId - 1 ].aspPrompt,
            dimId: aspList[ aspId - 1 ].dimId,
            dimName: aspList[ aspId - 1 ].dimName,
            dateTime: moment().format('YYYY/MM/DD h:mm:ss:SS')
        }
        if (firstSelection === 0) {
            if (props.results.length === 0) {
                let tempSession = props.session
                tempSession.startTime = moment()
                props.updateSession(tempSession)
            }
            setFirstSelection(aspId)
            let tempButtonState = buttonState
            tempButtonState[aspId] = true
            setButtonState(tempButtonState)
            selectionObj.selectOrder = 1
        } else {
            let tempButtonState = buttonState
            tempButtonState[aspId] = true
            setButtonState(tempButtonState)
            selectionObj.selectOrder = 2
            const next = page +1 
            nextPage("", next)
        }
        let tempResults = props.results
        tempResults.push(selectionObj)
        props.updateResults(tempResults)

console.log("SELETION-OBJ:", selectionObj)
    
    }

    function nextPage(event, nextPage){
        if (lastSet !== currentSet) setLastSet(currentSet)
        if (nextPage > page && nextPage < 26 ) {
            setButtonState( initBS() )
            setPage( nextPage )
            changeCurrentSet( nextPage )
            setFirstSelection( 0 )
            setProgress((( nextPage -1 ) / 25 ) * 100 )
            setDelayTimer(true)
        }
        if (nextPage === 26) {
            props.session.endTime = moment()
            const s = moment(props.session.startTime)
            const e = moment(props.session.endTime)
            const duration = Math.round(e.diff(s, 'minutes', true) * 100) / 100
            props.session.duration = duration
            props.updateSession(props.session)  
            props.updateProfileStage('Report')
        }
        setRedo(false)
        setHidePrev(false)
    }

    function changeCurrentSet(p){
        if (redo) {
            setCurrentSet(lastSet)
        } else {    
            const tempPattern = pattern[p - 1]
            const randPattern = randomizeSet(tempPattern)
            // Rearrange tempPattern as per the randPattern
            let tempSet = []
            for (let i = 0; i < 5; i++) {
                const itemIndex = tempPattern[ randPattern[ i ] ]
                tempSet.push(aspList[ itemIndex - 1 ])
            }
            setCurrentSet(tempSet)
            // if (p === 13) {
            //     alert("Reached the middle.")
            //     setPause(true)
            // }
        }
    }

    function previousPage(e,p){
        setRedo(true)
        setPage(p)
    }

    function setDelayTimer(boo){
        if (boo === false) {
            clearTimeout(delayInt)
            setDelay(false)
        } else {
            delayInt = setTimeout(function(){
                setDelayTimer(false);
            }, 300)
            setDelay(true)
        }
    }

    const displayTime = moment()

    moment.locale(l)

    return (
        <Fragment key={ l }>
            <Box m={ 6 } mb={ 4 } display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            {/* <Box mb={ 3 } width='100%' display="flex" flexDirection="column" alignItems="center" justifyContent="center" > */}
                <Typography variant="overline" paragraph>
                    <Moment format='LLL' >{ displayTime }</Moment>
                    {/* format='MMMM DD, YYYY h:mma' */}
                </Typography>
                <Typography paragraph> { props.session.name }, { t('paragraph', 'survey-please-click-tap', l) } </Typography>
                <Typography paragraph> { t('paragraph', 'survey-once-you-have', l) } </Typography>
                
                <Typography variant='button'> { page } { t('title', 'of', l) } 25</Typography>
                <LinearProgress variant="determinate" style={{ width: '90%' }} value={ progress } />
                <br/><br/>
                <Typography variant="h5">{ t('title', 'i-prefer', l) }</Typography>
            </Box>                
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">

                { currentSet.map((item) => (
                    <Fragment key={ item.id }>

                        { !delay && 
                            <Button 
                                key={ item.id }
                                disabled = { buttonState[item.id] }
                                m={ .75 } borderRadius = '22px'
                                variant="contained" 
                                size="medium" 
                                color="primary" width="320px" style={{textTransform: 'none'}}
                                onClick={ () => selectItem(item.id) } >
                                <Typography variant="h6">{ t('prompt', item.aspPrompt, l) }</Typography>
                            </Button>
                        }

                        { delay && 
                            <Box key={ item.id } width='320px' height='44px' m={ .75 } borderRadius = '22px' bgcolor='#f6f6f6'></Box>
                        }

                    </Fragment>
                ))}

                <Box width="100%" m={ 1 } display="flex" flexDirection="column" bgcolor="#ffffff" minWidth='200px' alignItems="center" justifyContent="center">
                    <Box width='320px' height="80px" m="20px" alignItems="left">

                        { page > 1 && !hidePrev &&
                            <Button variant="contained" size="small" onClick={ (event) => previousPage(event, page - 1) }>{ t('button', "go-back", l) }</Button>
                        }

                    </Box>
                </Box>
            </Box>
        </Fragment>
    )
}