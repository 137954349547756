
export default function GetTestProfile(results) {
    const testProf = {
        "dimCounts": [
        {
            "id": 3,
            "name": "impacting",
            "domDescription": "You are generally more motivated to have an impact on the world - something that you can see and point to as something you created. You are motivated to do this with things you have found meaningful or you may be drawn to impact things you find distasteful.",
            "nondomDescription": "The Impacting Need motivates obtaining, changing, creating and taking care of things that are meaningful to you. Being HIGH in this need means being MORE motivated to act and make an impact than individuals who are LOW in this need.",
            "functions": "Effect Change",
            "bgcolor": "#8C8C8F",
            "color": "#000000",
            "count": 15,
            "dominant": true,
            "level": "High"
        },
        {
            "id": 5,
            "name": "becoming",
            "domDescription": "You are generally more motivated to focus on accomplishments and personal growth. You may find yourself motivated to master things, to gain status, and gain wisdom throughout your life.",
            "nondomDescription": "The Becoming Need motivates how attuned you are with accomplishments and personal growth. Being HIGH in this need means being MORE motivated to master things, to gain status, and gain wisdom than individuals who are LOW in this need.",
            "functions": "Assess Efficacy",
            "bgcolor": "#080809",
            "color": "#ffffff",
            "count": 14,
            "dominant": true,
            "level": "High"
        },
        {
            "id": 4,
            "name": "belonging",
            "domDescription": "You are generally more motivated towards having and maintaining deep relationships. Being accepted may be a key motivation for you and you may be driven to conform to social norms.",
            "nondomDescription": "The Belonging Need motivates seeking, establishing, conforming, and maintaining social relationships with those we share meaning and impact. Being HIGH in this need means being MORE motivated to socialize with others than individuals who are LOW in this need.",
            "functions": "Gain Relationships",
            "bgcolor": "#FFF100",
            "color": "#000000",
            "count": 10,
            "dominant": false,
            "level": "Med"
        },
        {
            "id": 2,
            "name": "meaning",
            "domDescription": "You are generally more motivated towards seeking the freedom to discover and learn new things. You become attached to those you value while avoiding the things that make little sense or have limited value to you.",
            "nondomDescription": "The Meaning Need motivates seeking the freedom to discover and learn new things. Being HIGH in this need means being MORE motivated to be playful, explore, and want to make sense of things than individuals who are LOW in this need.",
            "functions": "Assess Value",
            "bgcolor": "#00ADEF",
            "color": "#000000",
            "count": 9.25,
            "dominant": false,
            "level": "Med"
        },
        {
            "id": 1,
            "name": "being",
            "domDescription": "You are generally more motivated to sensations in your body, your body’s physical needs, functions and abilities.",
            "nondomDescription": "The Being Need motivates how attuned you are with sensations in your body, the body's physical needs, functions, and abilities. Being HIGH in this need means being MORE motivated to be physically active than individuals who are LOW in this need.",
            "functions": "Maintain Body",
            "bgcolor": "#E9278A",
            "color": "#FFFFFF",
            "count": 8,
            "dominant": false,
            "level": "Med"
        }
        ],
        "aspCounts": [
        {
            "id": 1,
            "aspName": "Relax",
            "dimId": 1,
            "dimName": "Being",
            "bgcolor": "#E9278A",
            "color": "#FFFFFF",
            "aspDescription": "The degree to which we are motivated towards seeking time to unwind, reflect, and commune with nature.",
            "count": 0.5,
            "rank": "Low"
        },
        {
            "id": 2,
            "aspName": "Sleep",
            "dimId": 1,
            "dimName": "Being",
            "bgcolor": "#E9278A",
            "color": "#FFFFFF",
            "aspDescription": "The degree to which we are motivated to keep our body in equilibrium, feeling rested and ready for whatever may be coming our way.",
            "count": 1.75,
            "rank": "Low"
        },
        {
            "id": 3,
            "aspName": "Nourish",
            "dimId": 1,
            "dimName": "Being",
            "bgcolor": "#E9278A",
            "color": "#FFFFFF",
            "aspDescription": "The degree to which we are motivated to focus our senses on the opportunity to take in nutrient rich food and drink.",
            "count": 4,
            "rank": "Med"
        },
        {
            "id": 4,
            "aspName": "Move",
            "dimId": 1,
            "dimName": "Being",
            "bgcolor": "#E9278A",
            "color": "#FFFFFF",
            "aspDescription": "The degree to which we are motivated to engage in physical activity and to participate in activity which offer the opportunity for repetitive actions.",
            "count": 0.5,
            "rank": "Low"
        },
        {
            "id": 5,
            "aspName": "Rhythm",
            "dimId": 1,
            "dimName": "Being",
            "bgcolor": "#E9278A",
            "color": "#FFFFFF",
            "aspDescription": "The degree to which we are motivated to seek music and to move to its rhythm.",
            "count": 3.75,
            "rank": "Med"
        },
        {
            "id": 6,
            "aspName": "Autonomy",
            "dimId": 2,
            "dimName": "Meaning",
            "bgcolor": "#00ADEF",
            "color": "#000000",
            "aspDescription": "The degree to which we seek independence, giving us the freedom to think, decide and act on our own.",
            "count": 0.5,
            "rank": "Low"
        },
        {
            "id": 7,
            "aspName": "Curiosity",
            "dimId": 2,
            "dimName": "Meaning",
            "bgcolor": "#00ADEF",
            "color": "#000000",
            "aspDescription": "The degree to which we seek to explore and make discoveries in areas that have meaning to us. We want to uncover new things that are important to us or that we see as being important to others.",
            "count": 3,
            "rank": "Med"
        },
        {
            "id": 8,
            "aspName": "Safety",
            "dimId": 2,
            "dimName": "Meaning",
            "bgcolor": "#00ADEF",
            "color": "#000000",
            "aspDescription": "The degree to which we seek to ensure our own safety and security before we can comfortably explore other areas.",
            "count": 2.75,
            "rank": "Med"
        },
        {
            "id": 9,
            "aspName": "Play",
            "dimId": 2,
            "dimName": "Meaning",
            "bgcolor": "#00ADEF",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to explore our physical and intellectual boundaries in pursuit of what is fun and playful about ourselves, each other and the world.",
            "count": 1.5,
            "rank": "Low"
        },
        {
            "id": 10,
            "aspName": "Sense-Making",
            "dimId": 2,
            "dimName": "Meaning",
            "bgcolor": "#00ADEF",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to ask why and to find meaning and value in things and in activities.",
            "count": 4,
            "rank": "Med"
        },
        {
            "id": 11,
            "aspName": "Acquire",
            "dimId": 3,
            "dimName": "Impacting",
            "bgcolor": "#8C8C8F",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to acquire and possess things we value.",
            "count": 2.75,
            "rank": "Med"
        },
        {
            "id": 12,
            "aspName": "Create",
            "dimId": 3,
            "dimName": "Impacting",
            "bgcolor": "#8C8C8F",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to create new things in the world that impact our lives and the lives of others.",
            "count": 2.75,
            "rank": "Med"
        },
        {
            "id": 13,
            "aspName": "Order",
            "dimId": 3,
            "dimName": "Impacting",
            "bgcolor": "#8C8C8F",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to create plans for future actions and events, and to bring order to physical things in the world.",
            "count": 4,
            "rank": "Med"
        },
        {
            "id": 14,
            "aspName": "Impact",
            "dimId": 3,
            "dimName": "Impacting",
            "bgcolor": "#8C8C8F",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to change and improve the state of things.",
            "count": 4,
            "rank": "Med"
        },
        {
            "id": 15,
            "aspName": "Nurture",
            "dimId": 3,
            "dimName": "Impacting",
            "bgcolor": "#8C8C8F",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to help individuals based on our perception of their needs.",
            "count": 4,
            "rank": "Med"
        },
        {
            "id": 16,
            "aspName": "Admire",
            "dimId": 4,
            "dimName": "Belonging",
            "bgcolor": "#FFF100",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to recognize and emulate others that we perceive as admirable.",
            "count": 2.5,
            "rank": "Med"
        },
        {
            "id": 17,
            "aspName": "Conform",
            "dimId": 4,
            "dimName": "Belonging",
            "bgcolor": "#FFF100",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to act in accordance with the social norms established by groups we seek to belong to.",
            "count": 1.75,
            "rank": "Low"
        },
        {
            "id": 18,
            "aspName": "Connect",
            "dimId": 4,
            "dimName": "Belonging",
            "bgcolor": "#FFF100",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to seek the presence of other people and to actively engage with them.",
            "count": 4,
            "rank": "Med"
        },
        {
            "id": 19,
            "aspName": "Belong",
            "dimId": 4,
            "dimName": "Belonging",
            "bgcolor": "#FFF100",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to seek the acceptance and inclusion by groups we want to be part of.",
            "count": 2.75,
            "rank": "Med"
        },
        {
            "id": 20,
            "aspName": "Intimacy",
            "dimId": 4,
            "dimName": "Belonging",
            "bgcolor": "#FFF100",
            "color": "#000000",
            "aspDescription": "The degree to which we are motivated to have deep, meaningful, and intimate relationships.",
            "count": 1.5,
            "rank": "Low"
        },
        {
            "id": 21,
            "aspName": "Fairness",
            "dimId": 5,
            "dimName": "Becoming",
            "bgcolor": "#080809",
            "color": "#ffffff",
            "aspDescription": "The degree to which we are motivated to ensure those we value are treated fairly and given an equal opportunity.",
            "count": 2.75,
            "rank": "Med"
        },
        {
            "id": 22,
            "aspName": "Retaliate",
            "dimId": 5,
            "dimName": "Becoming",
            "bgcolor": "#080809",
            "color": "#ffffff",
            "aspDescription": "The degree to which we are motivated to forcefully react when we perceive others are taking advantage of us or someone whom we value.",
            "count": 4.5,
            "rank": "Med"
        },
        {
            "id": 23,
            "aspName": "Dominate",
            "dimId": 5,
            "dimName": "Becoming",
            "bgcolor": "#080809",
            "color": "#ffffff",
            "aspDescription": "The degree to which we are motivated to see things as competitions and to be strong and in control.",
            "count": 3.75,
            "rank": "Med"
        },
        {
            "id": 24,
            "aspName": "Status",
            "dimId": 5,
            "dimName": "Becoming",
            "bgcolor": "#080809",
            "color": "#ffffff",
            "aspDescription": "The degree to which we seek recognition and the respect and admiration of others.",
            "count": 3,
            "rank": "Med"
        },
        {
            "id": 25,
            "aspName": "Become",
            "dimId": 5,
            "dimName": "Becoming",
            "bgcolor": "#080809",
            "color": "#ffffff",
            "aspDescription": "The degree to which we are motivated to grow as individuals, master skills, and reach our true potential.",
            "count": 2.5,
            "rank": "Med"
        }
        ]
    }

    return testProf

}
