import React, { Fragment, useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@material-ui/core';
import { TeamList, TeamReport } from '../Components';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export default function Team(props) {
    const { t, l, team, route, subroute, session, updateTeam, updateSession, updateURL } = props
    const [ memberName, setMemberName ] = useState("")
    const [ profileCode, setProfileCode ] = useState("")
    const [ ready, setReady ] = useState(false)
    const [ newMemberFlag, setNewMemberFlag ] = useState(false)

// console.log("IN TEAM")
// console.log("Route: '" + route + "'")
// console.log("Subroute: '" + subroute + "'")

    useEffect(() => {
        if (team.length === 0) {

            console.log( "Team Is EMPTY" )

            if (session.name !== "" && session.profileCode !== "") {
                addMember(session.name, session.profileCode)
            }
        }
    }, [ team, session.name, session.profileCode ])

    function updateMemberName(value){
        if (memberName !== value) {
            setMemberName(value)
        }
        if (profileCode !== ""){
            updateReady(true)
        }
    }
    
    function updateProfileCode(value) {
        if (profileCode !== value) {
            setProfileCode(value)
        }
    }

    function addMember(newName, newCode){
        if (!newName && !newCode) {
            newName = memberName
            newCode = profileCode
        }
        
        const newId = uuidv4()
        const tmpMember = {
            id: newId,
            createdUtcDataTime: moment().toISOString(),
            name: newName,
            profileCode : newCode,
        }

        const tmpTeam = team
        tmpTeam.push(tmpMember)
        updateTeam(tmpTeam)

        updateMemberName("")
        updateProfileCode("")

        setNewMemberFlag(!newMemberFlag)
    }

    function updateReady(state){
        if (ready !== state) {
            setReady(state)
        }
    }

    function handleNameChange(event){
        setMemberName(event.target.value);
    };

    function handleCodeChange(event){
        setProfileCode(event.target.value);
    };

    function updateOrg(newOrg) {
        if (session.org !== newOrg) {
            session.org = newOrg
            updateSession(session)
            // checkIfDone()
        }
    }

    function viewTeamProfile(){
        updateURL({ lang:l, route: t("route", "team", l), subroute: t("route", "report", l) })
    }

    const listNotReady = (team.length < 2) ? true : false
    const memberNotReady = (memberName === '' || profileCode === '' || profileCode.length < 13) ? true : false
    
    return (        
        <Box width='100%' display='flex' alignItems="center" justifyContent="center" flexDirection="column" >

            { (route === t("route", "team", l)) && (subroute === "") && 
                <Box width='100%' pt={ 3 } display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Box display="flex" maxWidth='728px' mt={ 1 } ml={ 1 } mr={ 1 } p={ 2 } alignItems="center" justifyContent="center" 
                        flexDirection="column" border="solid 2px gray" borderRadius="8px">
                        <Box mb={ 1 }>
                            <Typography align="center" variant="h6" color="primary"><b>{ t('title', 'team-profiles', l) }</b></Typography>
                        </Box>

                        <TeamList team={ team } updateTeam={ updateTeam } route={ route } newMemberFlag={ newMemberFlag }/>
                        
                        <Box display="flex" width='100%' mt={ 1 } ml={ 1 } mr={ 1 } mb={ 3 } alignItems="center" justifyContent="center">
                            <Button variant="contained" color='primary' disabled={ listNotReady } 
                                onClick={ () => viewTeamProfile() }>{ t('button', 'view-team-profile', l) }</Button>
                        </Box>
                    </Box>
                    <Box display="flex" width='100%' mt={ 2 } ml={ 1 } mr={ 1 } alignItems="center" justifyContent="center">
                        <Box m={ 1 }>
                            <Typography align="center" variant="subtitle1" color="primary"><b>{ t('title', 'add-member', l) }</b></Typography>
                        </Box>
                    </Box>
                    <Box display="flex" width='100%' ml={ 1 } mr={ 1 } alignItems="center" justifyContent="center" flexWrap="wrap">
                        <Box>
                            <TextField id="memberName" value={ memberName } label={ t('fieldLabel', 'member-name', l) } variant="outlined" onChange={ handleNameChange } />
                        </Box>
                        <Box>
                            <TextField id="profileCode" value={ profileCode } label={ t('fieldLabel', 'profile-code', l) } variant="outlined" onChange={ handleCodeChange }
                            inputProps={{ maxLength: 13 }} />
                        </Box>
                    </Box>
                    
                    <Box display="flex" width='100%' mt={ 1 } ml={ 1 } mr={ 1 } mb={ 3 } alignItems="center" justifyContent="center">
                        <Button variant="contained" disabled={ memberNotReady } color='primary' onClick={ (event) => addMember() }>{ t('button', 'add-member', l) }</Button>
                    </Box>
                </Box>
            }

{ console.log( "ROUTE", route, "SUB", subroute )}

            { (route === t("route", "team", l)) && (subroute === t("route", "report", l)) &&
                <TeamReport { ...props } />
            }
        </Box>
    );
}