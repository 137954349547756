import React, { useEffect, useState } from 'react';
import { Box, Card, ListItem, Typography } from '@material-ui/core';
import { NeedsList, NeedsSearch } from '../Components';


export default function Needs(props) {
    const { t, l, updateURL } = props

    const needsData = require('../Assets/NeedsData.json').sort((a,b) =>  { 
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    })

    const propList = { t, l, needsData }

    return (
        <Box display="flex" flexDirection="row">
            
            {/* { needsList.map((item) => (
                <Box key={ item.id } minWidth="120px" m={ 1 } p={ 1 } style={{ backgroundColor:"#e3e3e3", borderWidth:".5px", borderStyle:"solid", borderRadius:"8px"}}>
                    {/* <Typography gutterBottom variant="h5" component="h2">{ t('title', item.level, l) }</Typography> */}
                    {/*<Typography gutterBottom variant="h5" component="h2">{ item.name }</Typography>
                    <Typography gutterBottom variant="button" component="h2">[{ item.radicalNeed }]</Typography>
                    <Typography gutterBottom variant="caption" component="h2">{ item.subNeed }</Typography>
                </Box>
            ))} */}

            {/* onClick={ () => updateURL( { route: t("route", "needs", l), subroute: t("route", "list", l), click: true } ) } */}

            <Box mt={ 4 } >
                <Typography gutterBottom variant="h5" align="center" component="h2">{ t( "title", "browse-list-of-needs", l) }</Typography>
                <NeedsList { ...propList } />
            </Box>
        </Box>
    );
}