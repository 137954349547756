import React, { Fragment, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Card, Button } from '../Core';
import RadicalLogoMagenta from '../Assets/RadicalLogoMagenta';
import YouLogo400 from '../Assets/YouologyLogo-400.png';

// install the react-wheel-of-prizes (wheel of fortune style wheel)


document.title = "Radical Purpose App"

export default function Game(props) {
    const [ game, setGame ] = useState([ { asps: [], levels: [] }, { asps: [], levels: [] }, { asps: [], levels: [] }, { asps: [], levels: [] }, { asps: [], levels: [] } ])
    const [ scenario, setScenario ] = useState(null)
    const [ currentDim ] = useState(0)
    const [ currentAsp, setCurrentAsp ] = useState(0)
    const [ currentLevel, setCurrentLevel ] = useState(0)

    const options = [ "Unaligned Beliefs", "Aligned Beliefs", "Unaligned Context", "Align Context" ]

    const dimList = require('../Assets/RpData.json').dims;
    const aspList = require('../Assets/RpData.json').asps;

    

    // useEffect(() => {
    //     spinnerRef.current.focus();
    // })


    // function advanceDim(){
    //     setCurrentDim(currentDim + 1)
    //     // const emptyDim = []
    //     // setGame.push(emptyDim)
    // }

    function randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function spinWheel(){
        const randIndex = randomInteger(0,3)
        const winner = options[randIndex]
        setScenario(winner)
    }

    function selectAsp(aspId){
        console.log(aspId)
        console.log(game)
        const tempGame = game
        tempGame[currentDim].asps.push(aspId)
        setGame(tempGame)
        setCurrentAsp(aspId)
        setCurrentLevel(0)
    }

    function selectLevel(level){
        console.log(level)
        setCurrentLevel(level)
    }

    const dim = dimList[currentDim]
    const asps = aspList.filter(a => a.dimId === (currentDim +1))

    // if (currentLevel !== 0) {
    //     const spinnerRef = useRef()
    //     spinnerRef.current.focus();
    // }

    return (
        <Fragment>
            <Box width='100%' display='flex' alignItems="center" justifyContent="center" >
                <Box minWidth='360px' maxWidth='720px' width='95%'
                    display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    
                    <Box display="flex" width='100%' mt={ 4 } alignItems="center" justifyContent="center">
                        <Box color="info.disabled" width={ 1 } p={ 1 } textAlign="center">
                            <Box mb={ 1 }>
                                <RadicalLogoMagenta width='48px' height='48px'/>
                            </Box>
                            <img src={ YouLogo400 } alt="YOU•OLOGY LOGO" width='400px'/>
                        </Box>
                    </Box>
                    <Box m={ 0 } style={{ maxWidth:"90vw", minWidth:"340px" }}>
                        <Card p={ 1 } style={{ maxWidth:"90vw", minWidth:"340px" }}>
                            <Typography variant="h5" gutterBottom={ false } align="center" ><strong>PLAY!</strong></Typography>
                        </Card>
                        <Card p={ 1 } style={{ maxWidth:"90vw", minWidth:"340px" }}>
                            <Typography variant="subtitle1" gutterBottom={ true } ><strong>How to win</strong></Typography>
                            <Typography variant="caption" gutterBottom={ true } >Live your Radical Purpose by aligning your unique essence, beliefs and environment. By maintaining high levels of motivation you gain more fulfillment points. <strong>The most fulfillment points at the end WINS!!!</strong></Typography>
                        </Card>
                    </Box>
                    <Card p={ 1 } style={{ maxWidth:"90vw", minWidth:"340px" }}>
                        <Typography variant="subtitle1" align="center" gutterBottom={ true } >
                            <strong>Current Dimension</strong>
                        </Typography>
                        <Typography variant="h5" align="center" gutterBottom={ true } >
                            <strong>{ dim.name }</strong>
                        </Typography>
                        <Typography variant="caption" align="center" gutterBottom={ true } >
                            { dim.domDescription }
                        </Typography>
                        <Typography variant="h6" align="center" gutterBottom={ true } >
                            Select An Aspects
                        </Typography>
                        <Box mt={ 2 } display="flex" justifyContent="center" flexWrap="wrap">
                            { asps.map((asp) => (
                                <Button variant={ asp.id === currentAsp ? "contained" : "outlined" }
                                    color="primary" disabled={ game[currentDim].asps.includes(asp.id) ? true : false }  
                                    key={ asp.id } onClick={ () => selectAsp(asp.id) }
                                    > { asp.aspName }</Button>
                            ))}
                            { currentAsp !== 0 &&
                                <Fragment>
                                    <Typography variant="caption" align="center" gutterBottom={ true } >{ asps[ currentAsp - 1 ].aspDescription }</Typography>
                                    <Box display="flex" justifyContent="center">
                                        <Box display="flex" width="50px" height="50px" m={ .5 } bgcolor={ currentLevel === 1 ? "#D00" : "#FFF" } color="primary" alignContent="center" 
                                            justifyContent="center" style= {{ borderStyle: "solid", borderColor: "#000", borderWidth: "2px", userSelect:"none" }}
                                            onClick={ () => selectLevel( 1 ) }>
                                            <Typography variant="h6" align="center" style= {{ lineHeight:"50px" }} >1</Typography>
                                        </Box>

                                        <Box display="flex" width="50px" height="50px" m={ .5 } bgcolor={ currentLevel === 2 ? "#D00" : "#FFF" } color="primary" alignContent="center" 
                                            justifyContent="center" style= {{ borderStyle: "solid", borderColor: "#000", borderWidth: "2px", userSelect:"none" }}
                                            onClick={ () => selectLevel( 2 ) }>
                                            <Typography variant="h6" align="center" style= {{ lineHeight:"50px" }} >2</Typography>
                                        </Box>

                                        <Box display="flex" width="50px" height="50px" m={ .5 } bgcolor={ currentLevel === 3 ? "#D00" : "#FFF" } color="primary" alignContent="center" 
                                            justifyContent="center" style= {{ borderStyle: "solid", borderColor: "#000", borderWidth: "2px", userSelect:"none" }}
                                            onClick={ () => selectLevel( 3 ) }>
                                            <Typography variant="h6" align="center" style= {{ lineHeight:"50px" }} >3</Typography>
                                        </Box>

                                        <Box display="flex" width="50px" height="50px" m={ .5 } bgcolor={ currentLevel === 4 ? "#D00" : "#FFF" } color="primary" alignContent="center" 
                                            justifyContent="center" style= {{ borderStyle: "solid", borderColor: "#000", borderWidth: "2px", userSelect:"none" }}
                                            onClick={ () => selectLevel( 4 ) }>
                                            <Typography variant="h6" align="center" style= {{ lineHeight:"50px" }} >4</Typography>
                                        </Box>
                                        <Box display="flex" width="50px" height="50px" m={ .5 } bgcolor={ currentLevel === 5 ? "#D00" : "#FFF" } color="primary" alignContent="center" 
                                            justifyContent="center" style= {{ borderStyle: "solid", borderColor: "#000", borderWidth: "2px", userSelect:"none" }}
                                            onClick={ () => selectLevel( 5 ) }>
                                            <Typography variant="h6" align="center" style= {{ lineHeight:"50px" }} >5</Typography>
                                        </Box>

                                        <Box display="flex" width="50px" height="50px" m={ .5 } bgcolor={ currentLevel === 6 ? "#D00" : "#FFF" } color="primary" alignContent="center" 
                                            justifyContent="center" style= {{ borderStyle: "solid", borderColor: "#000", borderWidth: "2px", userSelect:"none" }}
                                            onClick={ () => selectLevel( 6 ) }>
                                            <Typography variant="h6" align="center" style= {{ lineHeight:"50px" }} >6</Typography>
                                        </Box>
                                        
                                    </Box>
                                    { currentLevel !== 0 && 
                                        // <Box width="100%" display="flex" mt={ 3 } justifyContent="center" flexDirection="row">
                                        <Fragment>
                                            <Box display="flex" width="100%" justifyContent="center">
                                                <Typography variant="h5" gutterBottom={ true } >
                                                    Spin for Scenario
                                                </Typography>
                                            </Box>
                                            <Box display="flex" width="100%" justifyContent="center">
                                                <Button width="200px" hight="200px" variant="contained" m={ 4 } style={{ borderRadius: "100px"}}
                                                    onClick={ spinWheel }>
                                                    SPIN WHEEL
                                                </Button>
                                            </Box>
                                            <Box display="flex" width="100%" justifyContent="center">
                                            <Typography variant="button" gutterBottom={ true } >
                                                { scenario }
                                            </Typography>
                                            </Box>
                                        {/* // </Box> */}
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </Box>
                    </Card>

                </Box>
            </Box>
        </Fragment>
    )
}