import React, { useEffect, useState } from 'react';
import { Box, Card, CardHeader, CardContent, Fab, ListItem, Snackbar, Typography } from '@material-ui/core';
import { ArrowUpward } from '@material-ui/icons';
import BeingIcon from '../Assets/BeingIcon.png';
import MeaningIcon from '../Assets/MeaningIcon.png';
import ImpactingIcon from '../Assets/ImpactingIcon.png';
import BelongingIcon from '../Assets/BelongingIcon.png';
import BecomingIcon from '../Assets/BecomingIcon.png';

// const BeingIcon = require('../Assets/BeingIcon.png');


export default function NeedsList(props) {
    const { t, l, needsData } = props
    // const [ beingNeeds, setBeingNeeds ] = useState([])
    // const meaningNeeds = useState([])
    // const impactingNeeds = useState([])
    // const belongingNeeds = useState([])
    // const becomingNeeds = useState([])

    const transNeedsData = []
    needsData.forEach((n,i) => {
        const need =
            { "id": n.id, 
            "name": t('need', n.name, l).toUpperCase(),
            "description": "",
            "radicalNeed": t('need', n.radicalNeed, l),
            "subNeed": t('need', n.subNeed, l),
            "type": n.subNeed }
        transNeedsData.push(need)
    })

    
    const beingNeeds = transNeedsData.filter((n) => n.radicalNeed === t('need', "being", l))
        .sort((a,b) =>  { return a.name.localeCompare(b.name) })

    const meaningNeeds = transNeedsData.filter((n) => n.radicalNeed === t('need', "meaning", l))
        .sort((a,b) =>  { return a.name.localeCompare(b.name) }) 

    const impactingNeeds = transNeedsData.filter((n) => n.radicalNeed === t('need', "impacting", l))
        .sort((a,b) =>  { return a.name.localeCompare(b.name) }) 
        
    const belongingNeeds = transNeedsData.filter((n) => n.radicalNeed === t('need', "belonging", l))
        .sort((a,b) =>  { return a.name.localeCompare(b.name) }) 
        
    const becomingNeeds = transNeedsData.filter((n) => n.radicalNeed === t('need', "becoming", l))
        .sort((a,b) =>  { return a.name.localeCompare(b.name) })

    function gotoTop(){
        console.log("MOVING :)")
        window.scrollTo(0, 0)
    }

    return (
        <Box mb={ 4 }>
            <Snackbar  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={ true }>    
                <Fab  float='right' onClick={ () => gotoTop() } size='small' color='primary' ><ArrowUpward /></Fab> 
            </Snackbar>

            <Box mt={ 8 } mb={ 2 } display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <img src={ BeingIcon } alt="Being Needs Icon" width="80px" height="80px" />
                <Box ml={ 2 }>
                    <Typography variant="h4" align="center" component="h2" style={{ lineHeight: "50px"}}>
                        <strong>{ t("title", "being-needs", l).toUpperCase() }</strong>
                    </Typography>
                    <Typography variant="h6" component="h2">{ t("radicalNeedFunction", "being", l).toUpperCase() }</Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                { beingNeeds.map((item) => (
                    <Card key={ item.id } style={{ width:"280px" }}>
                        <CardHeader style={{ backgroundColor: "#e9278a"}} />    
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box p={ 1 }>
                                    <span display="inline-block" verticalalign="middle" height="100%"/>
                                    <img src={ BeingIcon } alt="Being Needs Icon" width="42px" height="42px" verticalalign="middle" />
                                </Box>
                                <Box>
                                    <Typography gutterBottom variant="h6" component="h2">{ item.name }</Typography>
                                    <Typography gutterBottom variant="caption" component="h2">{ t("title", "need", l) }: <strong>{ item.subNeed }</strong></Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Box mt={ 8 } mb={ 2 } display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <img src={ MeaningIcon } alt="Meaning Needs Icon" width="80px" height="80px" />
                <Box ml={ 2 }>
                    <Typography variant="h4" align="center" component="h2" style={{ lineHeight: "50px"}}>
                        <strong>{ t("title", "meaning-needs", l).toUpperCase() }</strong>
                    </Typography>
                    <Typography variant="h6" component="h2">{ t("radicalNeedFunction", "meaning", l).toUpperCase() }</Typography>
                </Box>
            </Box>
            <Box ml={ 2 } display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                { meaningNeeds.map((item) => (
                    <Card key={ item.id } style={{ width:"280px" }}>
                        <CardHeader style={{ backgroundColor: "#00adef"}} />    
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box p={ 1 }>
                                    <span display="inline-block" verticalalign="middle" height="100%"/>
                                    <img src={ MeaningIcon } alt="Meaning Needs Icon" width="42px" height="42px" verticalalign="middle" />
                                </Box>
                                <Box>
                                    <Typography variant="h6" component="h2">{ item.name }</Typography>
                                    <Typography variant="caption" component="h2">{ t("title", "need", l) }: <strong>{ item.subNeed }</strong></Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Box mt={ 8 } mb={ 2 } display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <img src={ ImpactingIcon } alt="Impacting Needs Icon" width="80px" height="80px" />
                <Box ml={ 2 }>
                    <Typography variant="h4" align="center" component="h2" style={{ lineHeight: "50px"}}>
                        <strong>{ t("title", "impacting-needs", l).toUpperCase() }</strong>
                    </Typography>
                    <Typography variant="h6" component="h2">{ t("radicalNeedFunction", "impacting", l).toUpperCase() }</Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap" >
                { impactingNeeds.map((item) => (
                    <Card key={ item.id } style={{ width:"280px" }}>
                        <CardHeader style={{ backgroundColor: "#8c8c8f"}} />    
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box p={ 1 }>
                                    <span display="inline-block" verticalalign="middle" height="100%"/>
                                    <img src={ ImpactingIcon } alt="Impacting Needs Icon" width="42px" height="42px" verticalalign="middle" />
                                </Box>
                                <Box>
                                    <Typography variant="h6" component="h2">{ item.name }</Typography>
                                    <Typography variant="caption" component="h2">{ t("title", "need", l) }: <strong>{ item.subNeed }</strong></Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Box mt={ 8 } mb={ 2 } display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <img src={ BelongingIcon } alt="Being Needs Icon" width="80px" height="80px" />
                <Box ml={ 2 }>
                    <Typography variant="h4" align="center" component="h2" style={{ lineHeight: "50px"}}>
                        <strong>{ t("title", "belonging-needs", l).toUpperCase() }</strong>
                    </Typography>
                    <Typography variant="h6" component="h2">{ t("radicalNeedFunction", "belonging", l).toUpperCase() }</Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                { belongingNeeds.map((item) => (
                    <Card key={ item.id } style={{ width:"280px" }}>
                        <CardHeader style={{ backgroundColor: "#fff100"}} />    
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box p={ 1 }>
                                    <span display="inline-block" verticalalign="middle" height="100%"/>
                                    <img src={ BelongingIcon } alt="Belonging Needs Icon" width="42px" height="42px" verticalalign="middle" />
                                </Box>
                                <Box>
                                    <Typography variant="h6" component="h2">{ item.name }</Typography>
                                    <Typography variant="caption" component="h2">{ t("title", "need", l) }: <strong>{ item.subNeed }</strong></Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            <Box mt={ 8 } mb={ 2 } display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center">
                <img src={ BecomingIcon } alt="Being Needs Icon" width="80px" height="80px" />
                <Box mx={ 2 } >
                    <Typography variant="h4" align="center" component="h2" style={{ lineHeight: "50px"}}>
                        <strong>{ t("title", "becoming-needs", l).toUpperCase() }</strong>
                    </Typography>
                    <Typography variant="h6" component="h2">{ t("radicalNeedFunction", "becoming", l).toUpperCase() }</Typography>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="center" flexWrap="wrap">
                { becomingNeeds.map((item) => (
                    <Card key={ item.id } style={{ width:"280px" }}>
                        <CardHeader style={{ backgroundColor: "#1d1d1d"}} />    
                        <CardContent>
                            <Box display="flex" flexDirection="row">
                                <Box p={ 1 }>
                                    <span display="inline-block" verticalalign="middle" height="100%"/>
                                    <img src={ BecomingIcon } alt="Becoming Needs Icon" width="42px" height="42px" verticalalign="middle" />
                                </Box>
                                <Box>
                                    <Typography variant="h6" component="h2">{ item.name.toUpperCase() }</Typography>
                                     <Typography variant="caption" component="h2">{ t("title", "need", l) }: <strong>{ item.subNeed }</strong></Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
            
            {/* *****************  USED TO CAPTURE ALL OF THE NEEDS IN A CSV LIST ******************* */}

            {/* <Box display="flex" flexDirection="row" flexWrap="wrap">
                    { needsData.map((item) => (
                                <Typography gutterBottom variant="h6" component="h2">{ " " + item.name + "," }</Typography>  
                    ))}
            </Box> */}
        </Box>
    )}