import React, { Fragment, useState } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Button, TextField } from '../Core';
import './App.css';

export default function Setup(props) {
    const { l, t, session, updateSession, updateTestMode, updateProfileStage } = props

    // const [ currentRow, setCurrentRow ] = useState(1)
    // const [ rowItems, setRowItems ] = useState([])
    const [ badName, setBadName ] = useState(false)
    const [ badEmail, setBadEmail ] = useState(false)
    const [ badCode, setBadCode ] = useState(false)
    

    function updateName(newName) {
        if (newName.length > 1) {
            setBadName(false)
            if (session.name !== newName) {
                const newSession = Object.assign({}, session)
                newSession.name = newName.trim();
                updateSession(newSession)
            }
        } else {
            setBadName(true)
        }
    }
    
    function validateEmail(email) {
        // Line below is used to prevent IDE error
        //eslint-disable-next-line
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }

    function updateEmail(newEmail) {
        if (validateEmail(newEmail)) {
            setBadEmail(false)
            if (session.email !== newEmail) {
                const newSession = Object.assign({}, session)
                newSession.email = newEmail
                updateSession(newSession)
            } 
        } else {
            setBadEmail(true)
        }
    }

    function updateCode(newCode) {
        if (validateCode(newCode)) {
            setBadCode(false)
            if (session.profileCode !== newCode) {
                const newSession = Object.assign({}, session)
                newSession.profileCode = newCode
                updateSession(newSession)
            } 
        } else {
            setBadCode(true)
        }
    }

    function validateCode(code) {
        
        if (code !== '') {
            // Line below is used to prevent IDE error
            //eslint-disable-next-line
            const re = /^[-A-Z0-9]{13}$/;
            return re.test(code);
        } else {
            return true
        }
        
      }

    function saveValues(){
        if (session.name !== '' && session.email !== ''){
            if (session.name === "test" && session.email === 'jleal67@gmail.com') {
                updateTestMode('true')
                updateProfileStage('Report')
            } else {
                if (session.profileCode !== '') {
                    updateProfileStage('Report')
                } else {
                    updateProfileStage('Instructions')
                }
            } 
        }
    }

    const notReady = (session.name === '' || session.email === '') ? true : false

    return (
        <Fragment>
            <Box maxWidth="728px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexWrap='wrap'>
                <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                    <Typography variant="subtitle2" align="center">
                        { t('title', 'your-rights', l) } <strong>{ session.name.toUpperCase() }</strong>
                    </Typography>
                    <Typography variant="body2" align="center">
                        { t('paragraph', 'the-personal-information-you-provide', l) }
                    </Typography>
                </Box>
            </Box>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                <Divider width='50%' variant="middle" style={{ margin: '36px' }} />
                <Box maxWidth='90%' m={ 1 } display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" flexWrap="wrap">
                    <Box>
                        <TextField m={ 1 } width= '250px' id="name" label={ t('fieldLabel', 'name', l) } variant="outlined" onChange={(event) => updateName(event.target.value)}/>
                        
                        { badName && <Box ml={ 1 } mr={ 1 }>
                            <Alert severity="error">
                                { t('alert', 'proper-name-required', l) }
                            </Alert>
                        </Box> }
                    
                    </Box>
                    <Box>
                        <TextField m={ 1 } width= '250px' id="email" label={ t('fieldLabel', 'email', l) } variant="outlined" onChange={(event) => updateEmail(event.target.value)}/>

                        { badEmail && <Box ml={ 1 } mr={ 1 }>
                            <Alert severity="error">
                                { t('alert', 'valid-email-required', l) }
                            </Alert>
                        </Box> }

                    </Box>
                    <Box>
                    <TextField m={ 1 } width= '250px' id="code" label={ t('fieldLabel', 'profile-code', l) } variant="outlined" onChange={(event) => updateCode(event.target.value)}/>

                        { badCode && <Box ml={ 1 } mr={ 1 }>
                            <Alert severity="error">
                                { t('alert', 'proper-code-required', l) }
                            </Alert>
                        </Box> }

                    </Box>
                </Box>

                <Box m={ 1 } display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Button m={ 1 } width='160px' height='40px' color='primary' id="submit" variant="contained" disabled={ notReady } onClick={ saveValues } >{ t('button', 'continue', l) }</Button>
                </Box>
            </Box>
        </Fragment>
    );
}