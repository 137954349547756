// ROUTING FUNTIONS

let langs = []
let routeMatrix = []
let t = ""

export function setRouteVars(newLangs, newRouteMatrix, newT) {
    langs = newLangs
    routeMatrix = newRouteMatrix
    t = newT
}

export function validateRoute( path ) {
    return routeInRouteMatrix( path )
}

export function getLangRoute(lang, path) {
    const allRoutes = buildRouteNames()
    // GET ROOT VALUES (EN) OF ROUTE
    const rootRoute = getRootRoute(path)

    // GET NAME VALUES OF ROUTE => NEW LANGUAGE
    let newRoute = { lang: lang, route: "", subroute: "" }
    allRoutes.forEach((r) => {
        if ( r.root === rootRoute.route && r.lang === lang ) {
            newRoute.route = r.name
            if ( rootRoute.subroute !== "" && r.subroutes.length ) {
                r.subroutes.forEach((sr) => {
                    if (sr.root === rootRoute.subroute ) newRoute.subroute = sr.name
                })
            }
        }
    })
    return newRoute
}

export function getRootRoute( path ) {
    const allRoutes = buildRouteNames()
    const rootRoute = { route: "", subroute: "" }
    allRoutes.forEach((r) => {
        if ( r.name === path.route && r.lang === path.lang ) {
            rootRoute.route = r.root
            if ( path.subroute !== "" && r.subroutes.length > 0 ) {
                r.subroutes.forEach((sr) => {
                    if (sr.name === path.subroute ) rootRoute.subroute = sr.root
                })
            }
        }
    })
    return rootRoute
}


export function validateSubroute(testRoute, testSubroute, lang) {
    let subrouteResult = { found: false, newSubroute: "", rootName: "", mismatch: false }

    // STEP ONE GET TEST ROUTE AND SUBROUTE TO ROOT VALUES

    const routeResult = routeInRouteMatrix(lang, testRoute, testSubroute)

    // TEMPORARY SOLUTION **** NEED TO BE REWRITTEN

console.log("SUBROUTE", testSubroute)

    // ONLY HANDLES TEAM SUBROUTE
    if ( testRoute === 'team' && testSubroute === 'report' && lang === 'en' ){
        subrouteResult.found = true
    } else if ( testRoute === 'equipo' && testSubroute === 'reporte' && lang === 'es' ){
        subrouteResult.found = true
    } else if ( testRoute === 'equipe' && testSubroute === 'relatório' && lang === 'pt' ){
        subrouteResult.found = true
    } else {
        subrouteResult.mismatch = true
        if ( testSubroute === 'report' && lang === 'en' ) {
            //THIS IS NOT THE WAY TO T DO THIS
        }
    }

    // if ((testSubroute === t("route", testSubroute, 'en')) || (testSubroute === t("route", testSubroute, 'es')) || (testSubroute === t("route", testSubroute, 'pt'))) {
    //     subrouteResult.found = true
    //     if ((testSubroute === t("route", testSubroute, 'en')) && (lang !== 'en')) {
    //         subrouteResult.mismatch = true
    //         subrouteResult.newSubroute = t("route", testSubroute, 'es')
    //     }
    //     if ((testSubroute === t("route", testSubroute, 'es')) && (lang !== 'es')) {
    //         subrouteResult.mismatch = true
    //         subrouteResult.newSubroute = t("route", testSubroute, 'en')
    //     }
    //     if ((testSubroute === t("route", testSubroute, 'pt')) && (lang !== 'pt')) {
    //         subrouteResult.mismatch = true
    //         subrouteResult.newSubroute = t("route", testSubroute, 'en')
    //     }
    // } 
    return subrouteResult
}

function routeInRouteMatrix( path ) {
    const routeNames = buildRouteNames()

    let result = { found: false, newName: "", rootName: "", mismatch: false }
    const newRoute = { lang: path.lang, route: "", subroute: "" }

    routeNames.forEach((r) => {
        if ( path.route === r.name ) {
            if (path.lang === r.lang) { 
                newRoute.route = path.route
                if ( path.subroute !== "" && r.subroutes.length > 0 ) {
                    r.subroutes.forEach((sr) => {
                        if (sr.name === path.subroute ) newRoute.subroute = path.subroute
                    })
                }

            }
        }     
    })

    // if (result.mismatch) {
    //     routeNames.forEach((route) => {        
    //         if (result.rootName === route.root) {        
    //             if (route.lang === lang ) result.newName = route.name
    //         }
    //     })
    // }
    // return result

    return newRoute
}

function buildRouteNames(){
    const routeNames = []
    langs.forEach((lingo) => {
        routeMatrix.forEach((r) => {
            const langRoute = { root: r.root, name: t("route", r.root, lingo), lang: lingo, subroutes:[] }
            if (r.subroutes.length) {
                r.subroutes.forEach((sr) => {
                    langRoute.subroutes.push({ root: sr.root, name: t("route", sr.root, lingo) })
                })
            }
            routeNames.push(langRoute)
        })
    })

    // console.log("ROUTNAMES",routeNames)

    return routeNames
}