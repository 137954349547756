import React from 'react';
import { Box, CardContent, Typography } from '@material-ui/core';
import { Card } from '../Core';
import { Aspects } from '../Components';

export default function Dimensions(props) {
    const { t, l, profile } = props

    if (profile === 0) return null

    let dimCounts = profile.dimCounts

    // sort dims by Dim Order: Being, Meaning, Impacting, Belonging, Becoming 
    // dimCounts.sort((a,b) => {
    //     return a.id - b.id;
    // })

    console.log("DIMSARRAY", dimCounts)

    return (
        <Box maxWidth="728px">
            { dimCounts.map((item) => (
                // <Fragment key={ item.id }>
                    <Card width='100%' height='100%' className="pagebreak" key={ item.id }>
                        <Box maxWidth="728px" height="110px" display="flex" justifyContent="center" alignItems="center" 
                            bgcolor={item.bgcolor} color={item.color}> 
                            <Typography variant="h4" component="h1" style={{ lineHeight: "110px" }}>{ t('radicalNeed', item.name, l).toUpperCase() }</Typography>
                        </Box>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">{ t('title', item.level, l) }</Typography>
                            <Typography gutterBottom variant="button" component="h2">{ t('radicalNeedFunction', item.name, l) }</Typography>
                            <Typography variant="body2" color="textSecondary" component="p">{ t('radicalNeedDesc', item.name, l) }</Typography>
                            <Box display="flex" flexDirection='column' flexWrap="wrap">
                                <Aspects t={ t } l={ l } profile={ profile } itemId={ item.id } />
                            </Box>
                        </CardContent>
                    </Card>
                // </Fragment>
            ))}
        </Box>
    );
}