import React, { Fragment, useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { Button } from '../Core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Dimensions, BuildRadar, ProfileCode, CodeDialog, Exercise } from '.';
import useWindowSize from "../Functions/useWindowSize.js";
import { SendEmail, calcProfile, ProfileCode2Results } from '../Functions';

export default function Report(props) {
    const { t, l, session, updateSession, profile, updateProfile, testMode, results, 
        updateResults, testProf, updateProfileStage, versionNum } = props
    
    const [ reportRun, setReportRun ] = useState(false)
    const [ durationRange, setDurationRange ] = useState(null)
    const [ radarDiam, setRadarDiam ] = useState(null)
    const [ radarContHeight, setRadarContHeight ] = useState(null)
    const [ open, setOpen ] = useState(false);
    const [ isMetaData, setIsMetaData ] = useState(true);
    const maxDim = useWindowSize()
    


    //const [ printMode, setPrintMode ] = useState(false)
    const printMode = false
    const minDuration = 3 // number of minutes it should at least take
    const maxDuration = 8 // number of minutes it should not be more than
    

    if (radarDiam !== maxDim) {
        setRadarDiam(maxDim)
        setRadarContHeight (maxDim + 25)
    }

    let sendEmail = true
    
    useEffect(() => {
        const run = session.reportRun ? true : false
        
        if (profile === 0) {
            let tempResults = []
            let tempProfile = ""
            // full results
            if (results.length > 25 ) {
                tempProfile = calcProfile(results)
                sendEmail = false
            } else {
                // either no results or there is a profileCode
                if (session.profileCode.length === 13) {
                    tempResults = ProfileCode2Results(session.profileCode)
                    tempProfile = calcProfile(tempResults, "profilecode")
                    sendEmail = false
                }
            }
            updateResults(tempResults)
            
// console.log("Calculated Profile Below")
// console.log(profile)

            // const sortedProf = sortProfile(profile) // keep profile in needs order
            updateProfile(tempProfile)
            assessDuration()
        }
        // DISPLAY THE TEST PROFILE 
        if (testMode) {
            
// console.log("Setting Test Profile")
// console.log(testProf)

            updateProfile(testProf)
            setDurationRange('average')
        }

        if ( !run ) {
            let tmpSession = session
            tmpSession.reportRun = true
            updateSession(tmpSession)
        } else {
            setReportRun(true)
        }
    }, [])

    // function sortProfile(prof){
    //     prof.dimCounts.sort(function (a, b) {
    //         return b.count - a.count;
    //       });
    //     return prof
    // }

    function assessDuration(){
        let range = 'average'

        console.log("DURATION", session.duration)

        if (session.duration === '' ) {
            range = 'noData'
        } else if(session.duration < minDuration ) {
            range = 'tooFast'
        } else if (session.duration > maxDuration) {
            range = 'tooSlow'
        }
        setDurationRange(range)
    }

    function restartSurvey(){
        const tmpSession = session
        tmpSession.profileCode = ""
        tmpSession.duration = ""
        tmpSession.endTime = ""
        tmpSession.startTime = ""
        tmpSession.reportRun = false
        updateSession(tmpSession)
        localStorage.removeItem('results')
        updateResults([])
        updateProfileStage('Survey')
    }

    function printReport(){
        if (printMode === false) {
            window.print()
        }
    }
    
    let notTest = true
    if (session.name === "test" && session.email === 'jleal67@gmail.com') { 
        notTest = false
    }
    const durationMinutes = Math.floor(session.duration)
    const durationSeconds = String(Math.round(60 * (session.duration - durationMinutes)))

    console.log(profile)

    if (radarDiam === null || radarContHeight === null) return null
    if (radarContHeight === null) return null



    moment.locale(l)
    const displayTime = moment( session.startTime ).format("LLL")

    if (!session.startTime) { 
        if (isMetaData !== false) setIsMetaData(false)
    }

    function openDialog(){
        setOpen(true)
    }

    function closeDialog(){
        setOpen(false)
    }

    return (
        <Fragment>
            <Box key={ session.profileCode } display='flex' maxWidth='1024px' m={ 6 } mt={ -10 } flexDirection="column" alignItems="center" 
                justifyContent="center" className="pagebreak">
                <Typography variant="h5" align='center' paragraph>{ t('title', 'radical-purpose-profile-for', l) }</Typography>
                <Typography variant="h5" align='center' paragraph><strong>{ session.name }</strong></Typography>
            
                { isMetaData && 
                    <Box>
                        <Typography variant="subtitle1" align='center' paragraph>{ t('title', 'created', l) }: { displayTime } </Typography>
                        <Typography variant="overline" align='center' paragraph>{ t('title', 'completed-in', l) } <strong>{ durationMinutes }</strong> { t('title', 'minutes', l) } <strong>{ durationSeconds }</strong> { t('title', 'seconds', l) }</Typography>
                    </Box>
                }

                <Box className="noprint">
                    <Button ml={ 0 } mt={ 2 } mb={ 3 } color='default' size="small" id="retry" variant="contained" onClick={ printReport } >{t('button', 'print-report', l)}</Button>
                </Box>

                { (durationRange === 'tooSlow') && 
                    <Alert severity="warning">
                        <AlertTitle>{ t('title', 'accuracy-note', l) }</AlertTitle>
                        { t('paragraph', 'based-on-our-research-tooslow', l) } { maxDuration } { t('paragraph', 'minutes-may-not-be', l) } { t('paragraph', 'generally-accurate-profiles-tooslow', l) } { maxDuration } { t('paragraph', 'minutes-you-may-want', l) } 
                        <br/>
                        <Box className="noprint">
                            <Button ml={ 0 } mt={ 2 } mb={ 1 } color='default' size="small" id="retry" variant="contained" onClick={ restartSurvey } >{t('button', 'retake', l)}</Button>
                        </Box>
                    </Alert>
                }

                { (durationRange === 'tooFast') && 
                    <Alert severity="warning">
                        <AlertTitle>{ t('title', 'accuracy-note', l) }</AlertTitle>
                        { t('paragraph', 'based-on-our-research-toofast', l) } { minDuration } { t('paragraph', 'minutes-may-not-be', l) } { t('paragraph', 'generally-accurate-profiles-toosfast', l) } { minDuration } { t('paragraph', 'minutes-you-may-want', l) }
                        <br/>
                        <Box className="noprint">
                            <Button ml={ 0 } mt={ 2 } mb={ 1 } color='default' size="small" id="retry" variant="contained" onClick={ restartSurvey } >{t('button','retake', l)}</Button>
                        </Box>
                    </Alert>
                }

                <Box width="90%" mb={ 1 } mt={ 2 } p={ 1 } borderRadius="6px" alignItems="center" justifyContent="center" fontWeight="fontWeightMedium">
                    <Typography variant="h5" align="center">{ t('title', 'your-five-radical-needs', l) }</Typography>
                </Box>
                <Box width="90%" mb={ 2 } p={ 1 } borderRadius="6px" alignItems="center" justifyContent="center">
                    <Typography variant="body2" color="inherit" align="left" paragraph>{ t('paragraph', 'the-radical-purpose-framework', l) }</Typography>
                    <Typography variant="body2" color="inherit" align="left" paragraph>{ t('paragraph', 'people-are-usually-unaware', l) }</Typography>
                </Box>
            </Box>

            {/* PAGE BREAK */}

            <Box width="100%" display="flex" maxWidth='1024px' flexDirection="column" alignItems="center" 
                justifyContent="center" className="pagebreak">
                <Box width="90%" mb={ 1 } mt={ 2 } color="#fff" bgcolor="#000" p={ 1 } borderRadius="6px" alignItems="center" justifyContent="center" fontWeight="fontWeightMedium">
                    <Typography variant="h5" color="inherit" align="center">{ t('title', 'your-needs-radar', l) }</Typography>
                </Box>
                <Box width="100%" height={ radarContHeight } m={ .5 } mb={ 2 } display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" flexWrap="wrap">
                    { profile !== 0 &&
                        <BuildRadar l={ l } t={ t } profile={ profile } domState={ true } radarDiam={ radarDiam }/>
                    }
                </Box>
                <Box width="100%" m={ .5 } mb={ 2 } display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" flexWrap="wrap">
                    { profile !== 0 &&
                        <ProfileCode profile={ profile } session={ session } updateSession={ updateSession } domState={ true } l={ l } t={ t }/>
                    }
                </Box>
            </Box>

            {/* PAGE BREAK */}

            <Box width="100%" display="flex" maxWidth='1024px' flexDirection="column" alignItems="center" justifyContent="center">
                <Box width="90%" mb={ 1 } mt={ 2 } color="#fff" bgcolor="#000" p={ 1 } borderRadius="6px" alignItems="center" justifyContent="center" fontWeight="fontWeightMedium">
                    <Typography variant="h5" color="inherit" align="center">{ t('title', 'your-radical-needs', l) }</Typography>
                </Box>
                <Box width="90%" mb={ 2 } p={ 1 } borderRadius="6px" alignItems="center" justifyContent="center">
                    <Typography variant="body2" color="inherit" align="center">{ t('paragraph', 'the-following-are-radical-needs', l) }</Typography>
                </Box>
                <Box width="100%" m={ .5 } mb={ 4 } display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center" flexWrap="wrap">
                    { profile !== 0 &&
                        <Dimensions t={ t } l={ l } profile={ profile } />
                    }
                </Box>

   
            {/* SEND EMAIL _ NO DISPLAY */}

            { ((profile !== 0) && (notTest === true) && (reportRun === false) && (sendEmail)) &&
                <SendEmail t={ t } l={ l } versionNum= { versionNum } session={ session } 
                    profile={ profile } updateSession={ updateSession } domState={ true } />
            }

            </Box>

            <Box width="100%" display="flex" maxWidth='1024px' flexDirection="column" alignItems="center" justifyContent="center">
                <Exercise t={ t } l={ l } />
            </Box>

            <Box width="100%" display="flex" maxWidth='1024px' flexDirection="row" alignItems="center" 
                justifyContent="center" className="noprint"> 
                <Box m={ 1 } display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Button m={ 1 } width='160px' height='40px' color='primary' id="submit" variant="contained" 
                        onClick={ restartSurvey } >{t('button', 'retake', l)}</Button>
                </Box>
                {/* <Box m={ 1 } display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Button m={ 1 } height='40px' color='primary' id="submit" variant="contained" 
                        onClick={ openDialog } >{t('button', 'add-profile-code', l)}</Button>
                </Box> */}
            </Box>
            <CodeDialog t={ t } l={ l } open={ open } closeDialog={ closeDialog }/>
        </Fragment>
    );
}