import React, { Fragment, useEffect } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { Card } from '../Core';
import { Setup, Instructions, Survey, Report } from '.';
// import MedallionEn from '../Assets/RadicalPurpose-Medallion-EN-300.jpg';
// import MedallionEs from '../Assets/RadicalPurpose-Medallion-ES-300.jpg';
// import MedallionPt from '../Assets/RadicalPurpose-Medallion-PT-300.jpg';

export default function Profile(props) {
    const { t, l, profileStage, setupDone, profileDone, updateProfileStage, results, updateResults, profile, updateProfile } = props
    const Medallion = require('../Assets/RadicalPurpose-Medallion-' + l.toUpperCase() + '-300.jpg');
    
    // console.log(setupDone)
    // console.log(profileDone)

    // **************  PROFILE STAGES IN ORDER *****************
    //
    //    Setup : Intro and get Name and Email > store [settings]
    //    Instructions : Show insructions
    //    Survey : Complete assessment > store [results]
    //    Report : Display Results
    //
    // *********************************************************
    
    useEffect(() => {
        if (profileStage === 'Setup')  {
            if (setupDone) {
                if (profileDone) {
                    updateProfileStage("Report")
                } else {
                    updateProfileStage("Instructions")
                }
            }
        }
    }, [ setupDone, profileDone ])


    return (
        <Fragment>  
            { ( profileStage === 'Setup' ) &&
                <Box width='100%' display='flex' alignItems="center" justifyContent="center" >
                    <Box minWidth='360px' maxWidth='728px' width='95%'
                        display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Box m={ 3 } display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            {/* <Typography variant="h6" align="center">Find out who you are at your core.</Typography> */}

                            <Box width="220px" height="220px" m={ 2 } mb={ 4 }>
                                <img src={ Medallion } alt="Radical Purpose Framework" width="220px" />
                            </Box>
                            <Typography variant="h6" gutterBottom={ true } >
                                { t('title', 'understanding-yourself', l) }
                            </Typography>
                            <Typography variant="body2" align="left" paragraph><strong>
                            { t('title', 'what-is-important-to-you', l) }
                                &nbsp;•&nbsp; { t('title', 'what-gives-you-energy', l) }
                                &nbsp;•&nbsp; { t('title', 'what-motivates-your-decisions', l) }</strong>
                            </Typography>
                            <Typography variant="body2" align="left" paragraph>
                                { t('paragraph', 'discover-your-innate-needs', l) }
                                &nbsp; { t('paragraph', 'learning-to-see-your-radical-purpose', l) } 
                            </Typography>
                            <Typography variant="body2" align="left">
                                <strong>
                                    { t('paragraph', 'the-radical-purpose-profile', l) }
                                </strong>
                            </Typography>
                            <br/>
                            <Link href="HTTPS://RADICALPURPOSE.ORG" variant='body2'>
                                { t('link', 'learn-more-at-radical-purpose', l) }
                            </Link>
                        </Box>
                    </Box>
                </Box>
            }

            { ( profileStage === 'Setup') &&
                <Box width='100%' display='flex' alignItems="center" justifyContent="center" >
                    <Card>
                        <Box minWidth='320px' maxWidth='1024px' width='95%' m={ 2 } mt={ 3 }
                            display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Box display="flex" flexDirection="column" width='100%' alignItems="center" justifyContent="center">
                                <Setup {...props} results= { results } updateResults={ updateResults } />
                            </Box>
                        </Box>
                    </Card>
                </Box>
            }

            { ( profileStage === 'Instructions' ) &&
                <Box width='100%' display='flex' alignItems="center" justifyContent="center" >
                    <Card>
                        <Box minWidth='360px' maxWidth='1024px' width='95%'
                            display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Box m={ 6 } display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Instructions {...props} changeLanguage= { props.changeLanguage }/>
                            </Box>
                        </Box>
                    </Card>
                </Box>
            }
                        {/* { ( props.profileStage === 'Credentials' ) &&
                            <Box display="flex" width="100%" mt='150px' alignItems="center" justifyContent="center">
                                <Credentials {...props} />
                            </Box>
                        } */}
            { ( profileStage === 'Survey') &&
                <Box width='100%' display='flex' alignItems="center" justifyContent="center" >
                    <Box minWidth='360px' maxWidth='1024px' width='95%'
                        display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <Box display="flex" flexDirection="column" width='100%' alignItems="center" justifyContent="center">
                            <Survey {...props} results= { results } updateResults={ updateResults } />
                        </Box>
                    </Box>
                </Box>
            }

            { ( profileStage === 'Report' ) &&
                <Box width="100%" mt='150px' alignItems="center" justifyContent="center">
                    <Report {...props} results={ results } profile={ profile } updateProfile={ updateProfile }/>
                </Box>
            }
        </Fragment>
    );
}