import React, { useState, useEffect, Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { BuildRadar, TeamSelect } from '.';
import { ProfileCode2Results, calcProfile } from '../Functions';

export default function TeamReport(props) {
    const { l, t, team } = props
    const [ radarDiam, setRadarDiam ] = useState(null)
    const [ teamProfiles, setTeamProfiles ] = useState([])
    const [ displayMode, setDisplayMode ] = useState("radars")
    
    useEffect(() => {
        const w = window.innerWidth
        const y = window.innerHeight
        const narrowest = (w > y) ? y : w
        const tmpDiam = (narrowest > 500) ? 500 : narrowest
        setRadarDiam(tmpDiam)
    }, [setRadarDiam])

    //BUILD TEAM RESULTS FROM PROFILECODE        
    if (team.length > 0) {
        const tmpTeam = team
        let needsUpdate = false
        team.forEach((e, i) => {
            if (!teamProfiles[i]?.results) {
                needsUpdate = true
                tmpTeam[i].results = ProfileCode2Results(e.profileCode)
            }
            if (!teamProfiles[i]?.profile) {
                needsUpdate = true
                tmpTeam[i].profile = calcProfile(tmpTeam[i].results, "profilecode")
            }
        });
        if ( needsUpdate ) { 
            setTeamProfiles(tmpTeam)
        }
    }

    teamProfiles.forEach((e) => {
        if (e.profile !== undefined) {
            if (e.profile.dimCounts === undefined) return null
        } else {
            return null
        }
    });

    return (
        <Box display='flex' alignItems="center" justifyContent="center" flexDirection="row" flexWrap="wrap">
            
            {/* <TeamSelect l={ l } t={ t }/> */}

            
            { displayMode === "radars" &&
                <Box maxWidth="1024px" display='flex' alignItems="center" justifyContent="center" flexDirection="row" flexWrap="wrap">
                    { team.map((item, index) => (
                        <Box m={ .5 } pt={ 1 } pb={ 2 } key={ index } border="1px solid #e8e8e8" borderRadius="40px" bgcolor="#fafafa" boxShadow="1px 2px #c0c0c0">
                            <Box>
                                <BuildRadar l={ l } t={ t } profile={ item.profile } domState={ true } radarDiam={ radarDiam } />
                            </Box>
                            <Box mt={ 1.5 } display="flex" justifyContent="center">
                                <Box borderRadius="13px" bgcolor="#f0f0f0" width={.3} height="26px"><Typography variant="button" align='center' paragraph><strong>{ item.profileCode }</strong></Typography></Box>
                            </Box>
                            <Box mb={ 1 }><Typography variant="h5" align='center'>{ item.name.toUpperCase() }</Typography></Box>
                        </Box>
                    ))}
                </Box>
            }
        </Box>
    )
}