

export default function ProfileCode2Results(code) {

    const theCode = {
        "1": 11, "2": 12, "3": 33, "4": 14, "5": 15, "6": 16, 
        "7": 21, "8": 22, "9": 23, "A": 24, "B": 25, "C": 26, 
        "D": 31, "E": 32, "F": 33, "G": 34, "H": 35, "I": 36,
        "J": 41, "K": 42, "L": 43, "M": 44, "N": 45, "O": 46,
        "P": 51, "Q": 52, "R": 53, "S": 54, "T": 55, "U": 56,
        "V": 61, "W": 62, "X": 63, "Y": 64, "Z": 65, "-": 66
    }

    let aspList = []
    let dimList = []
    const codeArr = code.split('')
    codeArr.forEach(e => {
        const val = theCode[e]
        // console.log(val)
        const valString = val.toString()
        // console.log(valString)
        const valArr = valString.split('')
        // console.log(valArr)
        valArr[0] = parseInt(valArr[0])
        valArr[1] = parseInt(valArr[1])
        aspList = aspList.concat(valArr)

        if (aspList.length === 26) {
            aspList = aspList.slice(0, 25)
        }
        // console.log(aspList)  
    });

    let dimCounter = 0

    aspList.forEach((e, i) => {
        dimCounter = dimCounter + e 
        if (i === 5 || i === 10 || i === 15 || i === 20 || i === 25 ){
            const level = dimCounter / 5
            dimList.push(level)
            dimCounter = 0
        }
    });


    const props = {}
    // props.dimList = dimList
    // props = aspList

    return aspList

}