import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import AspectScale from '../Assets/AspectScale';

export default function Aspects(props) {
    const profile = props.profile
    const t = props.t
    const l = props.l
    // const domState = props.domState
    const currentDim = props.itemId
    //const aspList = require('../Assets/RpData.json').asps;
    // const dimCounts = profile.dimCounts
    const aspCounts = profile.aspCounts
    const currentAspects = getDimAspects()

    function getDimAspects() {
        const curAsps = aspCounts.filter(item => item.dimId === currentDim );
        curAsps.sort((a, b) => {
            return  b.count - a.count;
        });
        return curAsps
    }

    return (
        <Box display="flex" flexDirection="row" flexWrap='wrap' justifyContent='center'>
            {/* <Typography gutterBottom variant="button" component="h5">{ currentAspects[0].dimName } Aspects</Typography> */}
            { currentAspects.map((item) => (    
                <Box maxWidth="320px" m={ 1 }  p={ .5 } bgcolor="#dfdfdf" borderRadius="5px" border="solid 1px gray" key={ item.id }>
                    <Box mb={ 1 } pt={ .5 } pb={ .5 } pl={ 1 } display='flex' bgcolor={ item.bgcolor } borderRadius="5px" justifyContent="space-between" alignItems="center">
                        <Box style={{ lineHeight: 1 }}>
                            <Typography variant="button" style={{ color: item.color, lineHeight: 1 }}>{ t('need', item.aspName, l) }</Typography><br/>
                            <Typography variant="caption" style={{ color: item.color, lineHeight: 1 }}>&nbsp;•&nbsp;{ t('title', item.rank, l) }</Typography>
                        </Box>
                        <AspectScale width="138" height="39" item={ item }/>
                    </Box>
                    <Typography variant="caption" style={{ lineHeight: 0.8 }}>{ t('needDesc', item.aspName, l) }</Typography>
                    {/* <Typography variant="caption" style={{ lineHeight: 0.8 }}>{ (item.aspName !== "sense-making" ) ? t('needDesc', item.aspName, l) : item.aspName }</Typography> */}
                </Box>
            ))}
        </Box>
    );
}