import React, { Fragment } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Button } from '../Core';
import './App.css';

export default function Instructions(props) {
    const t = props.t
    const l = props.l

    function nextStage(){
        localStorage.removeItem('results')
        props.updateResults([])
        props.updateProfileStage('Survey')
    }

    return (
        <Fragment>
            <Box minWidth="310px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexWrap='wrap'>
                <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" onChange={props.changeLanguage}>
                    <Typography variant="subtitle2" align="center">
                        { t('title', 'instructions', l) }
                    </Typography>
                </Box>
               <Box width="100%" maxWidth="728px" display="flex" flexDirection="column" alignItems="left" justifyContent="left">
                    <ol>
                        <Typography variant="body2" align="left">
                            <li>{ t('paragraph', 'you-will-be-provided', l) }</li>
                            <li><b>{ t('paragraph', 'you-need-to-select', l) }</b></li>
                            <li>{ t('paragraph', 'these-should-reflect-who', l) }</li>
                            <li>{ t('paragraph', 'dont-overthink-it', l) }</li>
                            <li>{ t('paragraph', 'when-you-finish', l) }</li>
                        </Typography>
                    </ol>
                </Box>
            </Box>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
                <Box m={ 1 } display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <Button m={ 1 } width='160px' height='40px' color='primary' id="submit" variant="contained" onClick={ nextStage } >{ t('button','continue', l) }</Button>
                </Box>
            </Box>
        </Fragment>
    );
}